<mat-sidenav-container>
  <mat-sidenav mode="side" disableClose="true" opened>
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <mat-sidenav #rightSidenav mode="over" position="end">
    @if (rightSidenav.opened) {
      <app-legal-forms-page></app-legal-forms-page>
    }
  </mat-sidenav>
</mat-sidenav-container>
