<div
  class="tile"
  [routerLink]="link.label !== 'MENU.TAX_STRATEGY' ? link.route : null"
  [routerLinkActive]=""
  #rla="routerLinkActive"
>
  @if (rla.isActive) {
    <div class="tile__bar"></div>
  }
  <div
    class="tile__content"
    [ngClass]="{
      'tile__content--active': rla.isActive,
    }"
    [matTooltip]="(link.label === 'MENU.TAX_STRATEGY' ? 'AVAILABLE_SOON' : null) | translate"
  >
    <img
      [src]="rla.isActive ? link.routeActiveIcon : link.routeIcon"
      [alt]="rla.isActive ? link.routeActiveIcon : link.routeIcon"
    />
    <p class="body-14-med">{{ link.label | translate }}</p>
    @if (link.new) {
      <div class="new">{{ 'NEW' | translate }}</div>
    }
    @if (link.beta) {
      <div class="new">{{ 'BETA' | translate }}</div>
    }
  </div>
</div>
