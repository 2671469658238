import { Action, createReducer, on } from '@ngrx/store';
import { TaxableCession } from '../../models/taxable-cession.model';
import * as AssessmentActions from '../actions/assessment.action';
import { Assessment, AssessmentStatus } from '../../models/assessment.model';
import moment from 'moment';

export interface State {
  assessment: Assessment;
  taxableCessions: TaxableCession[];
  currentFiscalYear: number;
  assessmentStatus: AssessmentStatus;
  assessmentETA: number;
  assessmentsByYear: Map<string, Assessment>;
}

export const initialState: State = {
  assessment: null,
  taxableCessions: [],
  currentFiscalYear: moment().year() - 1,
  assessmentStatus: null,
  assessmentETA: 0,
  assessmentsByYear: new Map<string, Assessment>([]),
};

const reducer = createReducer(
  initialState,
  on(AssessmentActions.setAssessmentAction, (state: State, { assessment }: any) => ({
    ...state,
    assessment: state.currentFiscalYear === assessment?.fiscalYear || !assessment ? assessment : state.assessment,
    assessmentETA:
      state.currentFiscalYear === assessment?.fiscalYear || !assessment ? assessment?.eta : state.assessmentETA,
  })),
  on(AssessmentActions.setLatestAssessmentsByYearAction, (state: State, { assessmentsByYear }: any) => ({
    ...state,
    assessmentsByYear,
  })),
  on(AssessmentActions.setTaxableCessionsAction, (state: State, { taxableCessions }: any) => ({
    ...state,
    taxableCessions,
  })),
  on(AssessmentActions.updateCurrentFiscalYearAction, (state: State, { currentFiscalYear }: any) => ({
    ...state,
    currentFiscalYear,
  })),
  on(AssessmentActions.setAssessmentStatusAction, (state: State, { assessmentStatus }: any) => ({
    ...state,
    assessmentStatus,
  })),
  on(AssessmentActions.startAssessmentAction, (state: State) => ({
    ...state,
    assessmentETA: 0,
  }))
);

export const assessmentReducer = (state: State | undefined, action: Action): State => reducer(state, action);
