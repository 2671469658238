<div
  class="form-input"
  [ngClass]="{
    'form-input--copied': copied,
    'form-input--copiable': copiable
  }"
  [matTooltip]="'ENTER_ON_TAX_WEBSITE' | translate"
  matTooltipPosition="above"
  [matTooltipDisabled]="copiable"
  (click)="copyValue()"
  (mouseleave)="resetCopied()"
  >
  {{ value }}

  @if (copiable) {
    <div #tooltip="matTooltip" class="copy" [matTooltip]="tooltipLabel | translate" matTooltipPosition="left"></div>
  }
</div>
