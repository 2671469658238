/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Configuration } from '../../../shared/models/configuration.model';
import { Page } from '../../../shared/models/page.model';
import { TransactionTag } from '../../../shared/models/tag.model';
import { ToastService } from '../../../shared/services/toast.service';
import { goToAction, pushTagAction } from '../../../shared/store/actions/shared.action';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { DEFAULT_TRANSACTIONS_FILTERS } from '../../constants/transactions.constant';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { UpdateResult } from '../../models/bulk.model';
import { TransactionStatsAggregation } from '../../models/transaction-stats-aggregation.model';
import {
  Transaction,
  TransactionFilters,
  TransactionUpdateDescriptionDto,
  TransactionUpdateDto,
} from '../../models/transaction.model';
import { SearchDTO } from '../../models/transactions-filters.model';
import { TransactionService } from '../../services/transaction.service';
import {
  deleteTransactionsAction,
  getTransactionAction,
  loadFiltersAction,
  loadNegativeBalancesAction,
  loadTransactionsAction,
  loadTransactionsStatsAction,
  saveTransactionAction,
  saveTransactionsAction,
  setCheckedTransactionsAction,
  setFiltersAction,
  setNegativeBalancesAction,
  setSelectedTransactionAction,
  setTransactionsPageAction,
  setTransactionsStatsAction,
  updateCheckedTransactionsDescriptionAction,
  updateTransactionPricesAction,
  updateCheckedTransactionsSubTypesAction,
  matchTransactionsAction,
  loadTransactionsWarningsAction,
  setTransactionsWarningsAction,
} from '../actions/transaction.action';
import * as fromTransaction from '../selectors/transaction.selector';
import { EMPTY } from 'rxjs';
import { CustomError } from '../../../shared/models/error.model';
import { ResponseSuccess } from '../../../shared/models/generic-response.model';
import { WarningOccurences } from '../../models/warning.model';

@Injectable()
export class TransactionEffects {
  loadTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTransactionsAction>>(loadTransactionsAction),
      switchMap((action: ReturnType<typeof loadTransactionsAction>) => {
        const filters: SearchDTO = { ...DEFAULT_TRANSACTIONS_FILTERS, ...action.filters };

        return this.transactionService.getTransactions(filters, action.page, action.sort, action.size).pipe(
          map((transactionsPage: Page<Transaction>) =>
            setTransactionsPageAction({
              transactionsPage,
            })
          )
        );
      })
    )
  );

  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFiltersAction>>(loadFiltersAction),
      switchMap(() => this.transactionService.getFilters()),
      map((filters: TransactionFilters) => setFiltersAction({ filters }))
    )
  );

  deleteTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteTransactionsAction>>(deleteTransactionsAction),
      withLatestFrom(
        this.transactionStore$.pipe(select(fromTransaction.selectTransactionsPage)),
        this.transactionStore$.pipe(select(fromTransaction.selectAppliedFilters)),
        this.sharedStore$.pipe(select(fromShared.selectConfiguration))
      ),
      switchMap(
        ([action, transactionPage, filters]: [
          ReturnType<typeof deleteTransactionsAction>,
          Page<Transaction>,
          SearchDTO,
          Configuration
        ]) => {
          const transactionIds: string[] = [];

          for (const transaction of action.transactions) {
            transactionIds.push(transaction.id);
          }

          let tag: TransactionTag;

          if (transactionIds.length === 1) {
            tag = {
              event: `delete_transaction`,
            };
          } else {
            tag = {
              event: `bulk_operation`,
              operation_type: `delete`,
            };
          }

          return this.transactionService.deleteTransactions(transactionIds).pipe(
            switchMap(() => {
              const formattedTransactionPage = transactionPage;
              formattedTransactionPage.content = [];

              let message: string;
              if (transactionIds.length === 1) {
                message = this.translateService.instant(`TRANSACTION_DELETED`);
              } else {
                message = this.translateService.instant(`TRANSACTIONS_DELETED`).replace(`{x}`, transactionIds.length);
              }

              this.toastService.success(message);

              return [
                pushTagAction({ tag }),
                loadTransactionsAction({ filters }),
                setCheckedTransactionsAction({ checkedTransactions: [] }),
                setTransactionsPageAction({
                  transactionsPage: formattedTransactionPage,
                }),
                setSelectedTransactionAction({
                  selectedTransaction: null,
                }),
              ];
            })
          );
        }
      )
    )
  );

  updateCheckedTransactionsSubTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateCheckedTransactionsSubTypesAction>>(updateCheckedTransactionsSubTypesAction),
      withLatestFrom(
        this.transactionStore$.pipe(select(fromTransaction.selectCheckedTransactions)),
        this.transactionStore$.pipe(select(fromTransaction.selectTransactionsPage)),
        this.transactionStore$.pipe(select(fromTransaction.selectAppliedFilters))
      ),
      switchMap(
        ([action, checkedTransactions, transactionPage, filters]: [
          ReturnType<typeof updateCheckedTransactionsSubTypesAction>,
          Transaction[],
          Page<Transaction>,
          SearchDTO
        ]) => {
          const transactionUpdateDTO: TransactionUpdateDto = {
            subType: action.subType,
            transactionIds: [],
          };

          for (const checkedTransaction of checkedTransactions) {
            transactionUpdateDTO.transactionIds.push(checkedTransaction.id);
          }

          const tag: TransactionTag = {
            event: `bulk_operation`,
            operation_type: `subtype`,
          };

          return this.transactionService.updateTransactionsSubType(transactionUpdateDTO).pipe(
            switchMap((updateResult: UpdateResult) => {
              const formattedTransactionPage = transactionPage;
              formattedTransactionPage.content = [];
              const translation = this.translateService.instant(`SUBTYPES_MODIFIED`);
              this.toastService.success(`${updateResult.matchedCount} ${translation}`);

              return [
                pushTagAction({ tag }),
                loadTransactionsAction({ filters, page: action.pageIndex, sort: action.sort }),
                setCheckedTransactionsAction({ checkedTransactions: [] }),
              ];
            })
          );
        }
      )
    )
  );

  updateCheckedTransactionsDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateCheckedTransactionsDescriptionAction>>(updateCheckedTransactionsDescriptionAction),
      withLatestFrom(
        this.transactionStore$.pipe(select(fromTransaction.selectCheckedTransactions)),
        this.transactionStore$.pipe(select(fromTransaction.selectTransactionsPage)),
        this.transactionStore$.pipe(select(fromTransaction.selectAppliedFilters)),
        this.sharedStore$.pipe(select(fromShared.selectConfiguration))
      ),
      switchMap(
        ([action, checkedTransactions, transactionPage, filters, configuration]: [
          ReturnType<typeof updateCheckedTransactionsDescriptionAction>,
          Transaction[],
          Page<Transaction>,
          SearchDTO,
          Configuration
        ]) => {
          const transactionUpdateDescriptionDto: TransactionUpdateDescriptionDto = {
            description: action.description,
            transactionIds: [],
          };

          for (const checkedTransaction of checkedTransactions) {
            transactionUpdateDescriptionDto.transactionIds.push(checkedTransaction.id);
          }

          const tag: TransactionTag = {
            event: `bulk_operation`,
            operation_type: `description`,
          };

          return this.transactionService.updateTransactionsDescription(transactionUpdateDescriptionDto).pipe(
            switchMap((updateResult: UpdateResult) => {
              const formattedTransactionPage = transactionPage;
              formattedTransactionPage.content = [];
              const translation = this.translateService.instant(`DESCRIPTIONS_ADDED`);
              this.toastService.success(`${updateResult.matchedCount} ${translation}`);

              return [
                pushTagAction({ tag }),
                loadTransactionsAction({ filters, page: action.pageIndex, sort: action.sort }),
                setCheckedTransactionsAction({ checkedTransactions: [] }),
                setTransactionsPageAction({
                  transactionsPage: formattedTransactionPage,
                }),
              ];
            })
          );
        }
      )
    )
  );

  saveTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof saveTransactionAction>>(saveTransactionAction),
      withLatestFrom(this.transactionStore$.pipe(select(fromTransaction.selectAppliedFilters))),
      switchMap(([action, appliedFilters]: [ReturnType<typeof saveTransactionAction>, SearchDTO]) =>
        this.transactionService.saveTransaction(action.transaction).pipe(
          switchMap((transaction: Transaction) => {
            this.toastService.success(this.translateService.instant(`TRANSACTION_SAVED`));

            const tag: TransactionTag = {
              event: `update_transaction`,
              transaction,
            };

            const actionsToDispatch: any[] = [];

            if (action.pageIndex !== undefined) {
              actionsToDispatch.push(
                loadTransactionsAction({ page: action.pageIndex, filters: appliedFilters, sort: action.sort })
              );
            }

            actionsToDispatch.push(loadTransactionsStatsAction(), pushTagAction({ tag }));

            return actionsToDispatch;
          })
        )
      )
    )
  );

  updateTransactionPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateTransactionPricesAction>>(updateTransactionPricesAction),
      switchMap((action: ReturnType<typeof updateTransactionPricesAction>) =>
        this.transactionService.saveTransaction(action.transaction).pipe(
          switchMap((transaction: Transaction) => {
            const tag: TransactionTag = {
              event: `update_token_price`,
              token_name: action.token,
              token_price: action.price,
            };

            return [pushTagAction({ tag }), getTransactionAction({ id: transaction.id })];
          })
        )
      )
    )
  );

  saveTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof saveTransactionsAction>>(saveTransactionsAction),
      switchMap((action: ReturnType<typeof saveTransactionsAction>) =>
        this.transactionService.saveTransactions(action.transactions).pipe(
          switchMap((updatedTransactions: Transaction[]) => {
            const msg = this.translateService.instant(`TRANSACTIONS_CREATED`);

            this.toastService.success(`${updatedTransactions.length} ${msg}`);

            const tag: TransactionTag = {
              event: `add_transactions`,
              transactions_count: updatedTransactions.length,
            };
            return [pushTagAction({ tag }), goToAction({ url: `transactions` })];
          })
        )
      )
    )
  );

  loadTransactionsStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTransactionsStatsAction>>(loadTransactionsStatsAction),
      switchMap((action: ReturnType<typeof loadTransactionsStatsAction>) => {
        return this.transactionService.getTransactionsStats().pipe(
          map((transactionsStats: TransactionStatsAggregation) =>
            setTransactionsStatsAction({
              transactionsStats,
            })
          )
        );
      })
    )
  );

  loadNegativeBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadNegativeBalancesAction>>(loadNegativeBalancesAction),
      switchMap((action: ReturnType<typeof loadNegativeBalancesAction>) => {
        return this.transactionService.getNegativeBalances().pipe(
          map((negativeBalances: TokenAndPlatformBalanceDetail[]) =>
            setNegativeBalancesAction({
              negativeBalances,
            })
          )
        );
      })
    )
  );
  getTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getTransactionAction>>(getTransactionAction),
      switchMap((action: ReturnType<typeof getTransactionAction>) => {
        return this.transactionService.getTransactionById(action.id).pipe(
          map((transaction: Transaction) =>
            setSelectedTransactionAction({
              selectedTransaction: transaction,
            })
          )
        );
      })
    )
  );

  matchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof matchTransactionsAction>>(matchTransactionsAction),
      withLatestFrom(this.transactionStore$.pipe(select(fromTransaction.selectAppliedFilters))),
      switchMap(([action, filters]: [ReturnType<typeof matchTransactionsAction>, SearchDTO]) =>
        this.transactionService.matchTransactions(action.transactions).pipe(
          switchMap((res: ResponseSuccess) => {
            if (res.success) {
              this.toastService.success(this.translateService.instant(`ASSOCIATED_TRANSACTIONS`));

              const tag: TransactionTag = {
                event: `bulk_operation`,
                operation_type: `match`,
              };

              return [pushTagAction({ tag }), loadTransactionsAction({ filters })];
            } else {
              this.toastService.error(this.translateService.instant(`ASSOCIATION_FAILED`));
              return EMPTY;
            }
          }),
          catchError((error: CustomError) => {
            const errorMessage: string =
              this.translateService.instant(error.errorCode || error.message) === error.errorCode
                ? error.message
                : this.translateService.instant(error.errorCode || error.message);

            this.toastService.error(errorMessage);

            return EMPTY;
          })
        )
      )
    )
  );

  loadTransactionsWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTransactionsWarningsAction>>(loadTransactionsWarningsAction),
      switchMap((action: ReturnType<typeof loadTransactionsWarningsAction>) =>
        this.transactionService.getTransactionsWarningsByType().pipe(
          map((warnings: WarningOccurences[]) => {
            // Not really aesthetic : used to init at 0 all warnings
            const transactionsWarnings: WarningOccurences[] = [
              { type: `INSUFFICIENT_BALANCE`, occurences: 0 },
              { type: `UNKNOWN_PRICE`, occurences: 0 },
              { type: `WITHDRAWAL_TO_CATEGORIZE`, occurences: 0 },
            ];

            warnings.forEach((warning: WarningOccurences) => {
              transactionsWarnings.forEach((w: WarningOccurences) => {
                if (w.type === warning.type) {
                  w.occurences = warning.occurences;
                }
              });
            });

            return setTransactionsWarningsAction({
              transactionsWarnings,
            });
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly transactionStore$: Store<fromTransaction.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly transactionService: TransactionService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService
  ) {}
}
