<section class="container">
  <section class="header">
    @if (currentForm === '3916-bis') {
      <h1>{{ 'DECLATE_ABROAD_ACCOUNTS' | translate }}</h1>
    }
    @if (currentForm !== '3916-bis') {
      <h1>{{ 'TAXABLE_TX_DECLARATION' | translate }}</h1>
    }

    <div>
      @if (currentForm !== 'es') {
        <mat-button-toggle-group
          class="custom-button-toggle-group"
          [(ngModel)]="currentForm"
          hideSingleSelectionIndicator
          >
          <mat-button-toggle value="3916-bis" disableRipple checked>{{ 'FORM_3916_BIS' | translate }}</mat-button-toggle>
          <mat-button-toggle
            value="2086"
            [disabled]="loading"
            disableRipple
            [matTooltipDisabled]="!loading"
            matTooltipPosition="above"
            [matTooltip]="'FORM_AVAILABLE_SOON' | translate"
            >
            <span class="demo">{{ 'PREVIEW' | translate }}</span>
            {{ 'FORM_2086' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      }

      <ng-container>
        @if (currentForm === '3916-bis') {
          <app-banner
            [title]="'FILL_IN_FORM_3916_BIS' | translate"
            [subtitle]="'DECLARE_ABROAD_ACCOUNTS' | translate"
          ></app-banner>
        }
        @if (currentForm === '2086') {
          <app-banner
            [title]="'FILL_2086_FORM' | translate"
            [subtitle]="'DECLARE_TAXABLE_TRANSFERS' | translate"
          ></app-banner>
        }
        @if (currentForm === 'es') {
          <app-banner
            [title]="'MUST_DECLARE' | translate"
            [subtitle]="'DECLARE_TAXABLE_TRANSFERS' | translate"
          ></app-banner>
        }
      </ng-container>
    </div>
  </section>

  <mat-divider></mat-divider>

  @if (currentForm === '3916-bis') {
    <section
      class="content"
    [ngClass]="{
      'content--skeleton': !accounts || !selectedPlatform,
    }"
      >
      @if (accounts && selectedPlatform) {
        <p class="body-14-med">{{ 'SELECT_PLATFORM' | translate }}</p>
        @if (accounts.size > 0) {
          <div class="platform-select-container">
            @if (selectedPlatform) {
              <img
                class="platform-logo"
                width="35"
                height="35"
                [src]="'/assets/img/logos/accounts/' + selectedPlatform + '.png'"
                [default]="'/assets/img/logos/accounts/default.svg'"
                alt=""
                />
            }
            <mat-select
              [(value)]="selectedPlatform"
              disableOptionCentering
              class="custom-select-variant"
              customFocus
              defaultClass="custom-select-variant"
              panelClass="custom-select-variant-panel"
              >
              <mat-option color="primary">
                <ngx-mat-select-search
                  [formControl]="platformSearchControl"
                  [placeholderLabel]="'SEARCH' | translate"
                  [noEntriesFoundLabel]="''"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-divider class="option-divider"></mat-divider>
              @if (usedPlatforms.size > 0) {
                <p class="body-12-med">{{ 'MY_ADDED_PLATFORMS' | translate }}</p>
                @for (platform of usedPlatformsOptions$ | async | keyvalue; track platform) {
                  <mat-option [value]="platform.key">
                    <div class="platform-option">
                      <img
                        class="platform-logo"
                        width="24"
                        height="24"
                        [src]="'/assets/img/logos/accounts/' + platform.key + '.png'"
                        [default]="'/assets/img/logos/accounts/default.svg'"
                        alt=""
                        />
                      {{ accounts.get(platform.key)?.name }}
                    </div>
                  </mat-option>
                }
              }
              @if (platforms.size > 0) {
                @if (usedPlatforms.size > 0) {
                  <mat-divider class="option-divider"></mat-divider>
                }
                @if (usedPlatforms.size > 0) {
                  <p class="body-12-med">{{ 'OTHER_PLATFORMS' | translate }}</p>
                }
                @for (platform of platformsOptions$ | async | keyvalue; track platform) {
                  <mat-option [value]="platform.key">
                    <div class="platform-option">
                      <img
                        class="platform-logo"
                        width="24"
                        height="24"
                        [src]="'/assets/img/logos/accounts/' + platform.key + '.png'"
                        [default]="'/assets/img/logos/accounts/default.svg'"
                        alt=""
                        />
                      {{ accounts.get(platform.key)?.name }}
                    </div>
                  </mat-option>
                }
              }
            </mat-select>
          </div>
        }
        @if (accounts.size > 0) {
          <div class="form-3916-bis">
            <div class="form-3916-bis__header">
              <p class="body-14">{{ 'TAX_RETURN_PREVIEW' | translate }}</p>
            </div>
            <mat-divider></mat-divider>
            <div class="form-3916-bis__content">
              <div class="form-3916-bis__content__title">
                <p class="body-14-bold">
                  {{ '3916_BIS_FORM_STEP_3' | translate }}
                </p>
                <p class="body-14-bold">
                  {{ 'DESIGNATION' | translate }}
                </p>
              </div>
              <div class="form-items">
                <div class="form-item">
                  <p class="body-14">{{ 'ACCOUNT_NUMBER' | translate }}</p>
                  <app-french-tax-form-input [value]="'ENTER_ACCOUNT_NUMBER' | translate"></app-french-tax-form-input>
                </div>
                <div class="form-item">
                  <p class="body-14">{{ 'OPENING_DATE' | translate }}</p>
                  <app-french-tax-form-input
                    [value]="'ENTER_ACCOUNT_DATE_OPNENING' | translate"
                  ></app-french-tax-form-input>
                </div>
                <div class="form-item">
                  <p class="body-14">{{ 'CLOSING_DATE' | translate }}</p>
                  <app-french-tax-form-input [value]="'FILL_IN_ACCOUNT_CLOSE' | translate"></app-french-tax-form-input>
                </div>
                <div class="form-item">
                  <p class="body-14">{{ 'ACCOUNT_DESIGNATION' | translate }}</p>
                  <app-french-tax-form-input
                    [copiable]="platformsMap.get(selectedPlatform)?.designation"
                    [value]="platformsMap.get(selectedPlatform)?.designation || 'DO_NOT_FILL_IN' | translate"
                  ></app-french-tax-form-input>
                </div>
              </div>
              <div class="form-items">
                <div class="form-item m-l-25">
                  <p class="body-14">{{ 'COUNTRY' | translate }}</p>
                  <app-french-tax-form-input
                    [copiable]="platformsMap.get(selectedPlatform)?.country"
                    [value]="platformsMap.get(selectedPlatform)?.country || 'DO_NOT_FILL_IN' | translate"
                  ></app-french-tax-form-input>
                </div>
                <div class="form-item m-l-25">
                  <p class="body-14">{{ 'ADDITIONAL_ADDRESS' | translate }}</p>
                  <app-french-tax-form-input
                    [copiable]="platformsMap.get(selectedPlatform)?.additionalAddress"
                    [value]="platformsMap.get(selectedPlatform)?.additionalAddress || 'DO_NOT_FILL_IN' | translate"
                  ></app-french-tax-form-input>
                </div>
                <div class="form-item m-l-25">
                  <p class="body-14">{{ 'STREET' | translate }}</p>
                  <app-french-tax-form-input
                    [copiable]="platformsMap.get(selectedPlatform)?.street"
                    [value]="platformsMap.get(selectedPlatform)?.street || 'DO_NOT_FILL_IN' | translate"
                  ></app-french-tax-form-input>
                </div>
                <div class="form-item m-l-25">
                  <p class="body-14">{{ 'COUNTRY_POSTCODE_CITY' | translate }}</p>
                  <app-french-tax-form-input
                    [copiable]="platformsMap.get(selectedPlatform)?.zipCode"
                    [value]="platformsMap.get(selectedPlatform)?.zipCode || 'DO_NOT_FILL_IN' | translate"
                  ></app-french-tax-form-input>
                </div>
                <div class="form-item">
                  <p class="body-14">{{ 'ACCOUNT_URL' | translate }}</p>
                  <app-french-tax-form-input
                    [copiable]="platformsMap.get(selectedPlatform)?.url"
                    [value]="platformsMap.get(selectedPlatform)?.url || 'DO_NOT_FILL_IN' | translate"
                  ></app-french-tax-form-input>
                </div>
              </div>
            </div>
          </div>
        }
      }
      @if (!accounts || !selectedPlatform) {
        <img class="rotating" width="22px" src="/assets/img/icons/loading-default.svg" alt="" />
      }
    </section>
  }

  @if (currentForm === '2086' || currentForm === 'es') {
    <section
      class="cessions-container"
    [ngClass]="{
      'cessions-container--skeleton': (currentForm === '2086' || currentForm === 'es') && (loading || !taxableCessions),
    }"
      >
      @if (!loading && taxableCessions) {
        <!-- Upgrade fishing -->
        @if (!showDemo && !hasPaid) {
          <div class="upgrade-fishing">
            <div class="upgrade-fishing__content">
              <h2>{{ 'UPDATE_YOUR_SUBSCRIPTION' | translate }}</h2>
              <p class="body-14">{{ 'FIND_OUT_TAXABLE_TRANSFERS' | translate }}</p>
            </div>
            <div class="upgrade-fishing__ctas">
              <button type="button" mat-flat-button color="primary" (click)="upgradeFishing()">
                {{ upgradeFishingCTA }}
              </button>
              <button type="button" mat-flat-button color="secondary" (click)="showDemo = true">
                {{ 'SHOW_FORM_PREVIEW' | translate }}
              </button>
            </div>
          </div>
        }
        <!-- No cessions -->
        @if (taxableCessions && taxableCessions.length === 0) {
          <div class="no-cessions">
            <img src="/assets/img/icons/no-cessions.svg" alt="no-cessions" />
            <h2>{{ 'NO_TAXABLE_TRANSFERS' | translate }}</h2>
            @if (assessment?.fiscalAuthority === 'FR') {
              <p class="body-14">
                {{ 'NO_NEED_FILL_2086' | translate }}
              </p>
            }
            @if (assessment?.fiscalAuthority === 'ES') {
              <p class="body-14">
                {{ 'NO_TAXABLE_TX' | translate }}
              </p>
            }
            <button type="button" mat-flat-button color="secondary" (click)="showDemoCessions()">
              {{ 'SHOW_FORM_PREVIEW' | translate }}
            </button>
          </div>
        }
        @if (taxableCessions && taxableCessions.length > 0) {
          <div
            class="title"
            [ngClass]="{ 'demo-filter': !hasPaid && !showDemo }"
            >
            <div class="title__infos">
              <p class="body-14-med">{{ taxableCessionsMsg }}</p>
              <p class="body-14-med">{{ 'BOX_COPY' | translate }}</p>
            </div>
            @if (!hasPaid) {
              <button
                mat-flat-button
                color="primary-flash"
                disableRipple
                (click)="upgradeFishing()"
                [disabled]="!showDemo"
                >
                {{ 'SUBSCRIBE' | translate }}
              </button>
            }
          </div>
        }
        <!-- FR -->
        @if (taxableCessions && taxableCessions.length > 0 && assessment.fiscalAuthority === 'FR') {
          <section
            class="cessions"
        [ngClass]="{
          'cessions--demo': showDemo,
          'demo-filter': !hasPaid && !showDemo,
        }"
            >
            <!-- First grid -->
            <div class="grid">
              <!-- First row -->
              @for (cession of displayedCessions; track cession; let first = $first; let last = $last; let index = $index) {
                <div
                  class="grid-header"
            [ngClass]="{
              first: first,
              'border-top-left': first,
              'border-top-right': last,
            }"
                  >
                  <p class="body-14-bold">{{ 'CESSION' | translate }} {{ taxableCessions?.indexOf(cession) + 1 }}</p>
                </div>
              }
              <div class="grid-header grid-header--code border-top-left">
                <p class="body-14-bold">211</p>
                <div class="info-tooltip" [matTooltip]="'TRANSFER_DATE' | translate" matTooltipPosition="right"></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['211']"
                  [copiable]="hasPaid || showDemo"
                ></app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code border-bottom-left">
                <p class="body-14-bold">212</p>
                <div
                  class="info-tooltip"
                  [matTooltip]="'PORTFOLIO_TOTAL_VALUE' | translate"
                  matTooltipPosition="right"
                ></div>
              </div>
              @for (cession of displayedCessions; track cession; let last = $last) {
                <app-french-tax-grid-cell
            [ngClass]="{
              'border-bottom-right': last,
            }"
                  [value]="cession['212']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
            </div>
            <!-- Second grid -->
            <div class="grid">
              <div class="grid-header grid-header--code border-top-left">
                <p class="body-14-bold">213</p>
                <div class="info-tooltip" [matTooltip]="'TRANSFER_PRICE' | translate" matTooltipPosition="right"></div>
              </div>
              @for (cession of displayedCessions; track cession; let last = $last) {
                <app-french-tax-grid-cell
            [ngClass]="{
              'border-top-right': last,
            }"
                  [value]="cession['213']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code">
                <p class="body-14-bold">214</p>
                <div class="info-tooltip" [matTooltip]="'TRANSFER_FEES' | translate" matTooltipPosition="right"></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['214']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code">
                <p class="body-14-bold">215</p>
                <div class="info-tooltip" [matTooltip]="'NET_TRANSFER_PRICE' | translate" matTooltipPosition="right"></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['215']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code">
                <p class="body-14-bold">216</p>
                <div class="info-tooltip" [matTooltip]="'COMPENSATIONS' | translate" matTooltipPosition="right"></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['216']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code">
                <p class="body-14-bold">217</p>
                <div
                  class="info-tooltip"
                  [matTooltip]="'NET_TRANSFER_PRICE_AFTER2' | translate"
                  matTooltipPosition="right"
                ></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['217']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code border-bottom-left">
                <p class="body-14-bold">218</p>
                <div
                  class="info-tooltip"
                  [matTooltip]="'NET_TRANSFER_PRICE_AFTER' | translate"
                  matTooltipPosition="right"
                ></div>
              </div>
              @for (cession of displayedCessions; track cession; let last = $last) {
                <app-french-tax-grid-cell
            [ngClass]="{
              'border-bottom-right': last,
            }"
                  [value]="cession['218']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
            </div>
            <!-- Last grid -->
            <div class="grid">
              <div class="grid-header grid-header--code border-top-left">
                <p class="body-14-bold">220</p>
                <div
                  class="info-tooltip"
                  [matTooltip]="'TOTAL_ACQUISITION_PRICE' | translate"
                  matTooltipPosition="right"
                ></div>
              </div>
              @for (cession of displayedCessions; track cession; let last = $last) {
                <app-french-tax-grid-cell
            [ngClass]="{
              'border-top-right': last,
            }"
                  [value]="cession['220']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code">
                <p class="body-14-bold">221</p>
                <div class="info-tooltip" [matTooltip]="'CAPITAL_FRACTIONS' | translate" matTooltipPosition="right"></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['221']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code">
                <p class="body-14-bold">222</p>
                <div
                  class="info-tooltip"
                  [matTooltip]="'RECEIVED_COMPENSATIONS' | translate"
                  matTooltipPosition="right"
                ></div>
              </div>
              @for (cession of displayedCessions; track cession) {
                <app-french-tax-grid-cell
                  [value]="cession['222']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
              <div class="grid-header grid-header--code border-bottom-left">
                <p class="body-14-bold">223</p>
                <div
                  class="info-tooltip"
                  [matTooltip]="'TOTAL_NET_ACQUISITION_PRICE' | translate"
                  matTooltipPosition="right"
                ></div>
              </div>
              @for (cession of displayedCessions; track cession; let last = $last) {
                <app-french-tax-grid-cell
            [ngClass]="{
              'border-bottom-right': last,
            }"
                  [value]="cession['223']"
                  [copiable]="hasPaid || showDemo"
                  >
                </app-french-tax-grid-cell>
              }
            </div>
          </section>
        }
        <!-- ES -->
        @if (taxableCessions && taxableCessions.length > 0 && assessment.fiscalAuthority === 'ES') {
          <section
            class="cessions"
            >
            <app-spanish-taxable-cession [taxableCession]="selectedTaxableCession"></app-spanish-taxable-cession>
          </section>
        }
      }
      @if ((currentForm === '2086' || currentForm === 'es') && (loading || !taxableCessions)) {
        <img class="rotating" width="22px" src="/assets/img/icons/loading-default.svg" alt="" />
      }
    </section>
  }

  <!-- FR -->
  @if (currentForm === '2086' && taxableCessions?.length > this.maxCessionsPerPage) {
    <section
      class="cessions-paginator"
      [ngClass]="{ 'demo-filter': !hasPaid && !showDemo }"
      >
      <p>
        {{ 'PAGE' | translate }} {{ cessionsPageIndex / this.maxCessionsPerPage + 1 }} {{ 'ON' | translate | lowercase }}
        {{ maxCessionsPage }}
      </p>
      <div class="ctas">
        <button mat-flat-button color="primary" [disabled]="cessionsPageIndex === 0" (click)="updateCessionPage(false)">
          {{ 'PaginatorPreviousPageLabel' | translate }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="cessionsPageIndex / this.maxCessionsPerPage + 1 === maxCessionsPage"
          (click)="updateCessionPage()"
          >
          {{ 'PaginatorNextPageLabel' | translate }}
        </button>
      </div>
    </section>
  }

  <!-- ES -->
  @if (currentForm === 'es' && taxableCessions?.length > 0) {
    <section class="cessions-paginator">
      <p>
        {{ 'TRANSACTION' | translate }} {{ selectedTaxableCessionIndex + 1 }} {{ 'ON' | translate | lowercase }}
        {{ taxableCessions?.length }}
      </p>
      <div class="ctas">
        <button
          mat-flat-button
          color="primary"
          [disabled]="selectedTaxableCessionIndex === 0"
          (click)="updateSelectedTaxableCession(false)"
          >
          {{ 'PREVIOUS' | translate }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="selectedTaxableCessionIndex === taxableCessions?.length - 1"
          (click)="updateSelectedTaxableCession()"
          >
          {{ 'NEXT' | translate }}
        </button>
      </div>
    </section>
  }
</section>
