<div class="container">
  @if (!validatedPayment) {
    <div class="loading">
      <img class="waltio-logo" src="/assets/img/logos/accounts/_MANUAL_LEDGER.png" alt="" width="26px" height="26px" />
      <img class="rotating" src="/assets/img/icons/loading-primary.svg" alt="" />
    </div>
    <p class="body-14-bold">{{ 'VERIFYING_PAYMENT' | translate }}</p>
  }

  @if (validatedPayment) {
    <img class="success" src="/assets/img/icons/check-full.svg" alt="" width="26px" height="26px" />
    <div class="payment-success">
      <p class="body-14-bold">{{ 'PAYMENT_CONFIRMED' | translate }}</p>
      <p class="body-14">{{ 'PAYMENT_SUCCESS_MESSAGE' | translate }}</p>
    </div>
  }
</div>
