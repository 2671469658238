<div
  class="toast"
  [ngClass]="{
  'toast--success': data.type === 'success',
  'toast--error': data.type === 'error',
}"
  >
  @if (data.type === 'success') {
    <img src="/assets/img/icons/success.svg" alt="" />
  }
  @if (data.type === 'error') {
    <img src="/assets/img/icons/error.svg" alt="" />
  }

  @if (data.type !== 'info') {
    <p class="body-14">{{ data.message }}</p>
  }
  @if (data.type === 'info') {
    <img [src]="data.message" alt="" />
  }

  @if (data.type !== 'info') {
    <img
      class="pointer"
      src="/assets/img/icons/close-white.svg"
      alt=""
      (click)="snackBarRef.closeWithAction()"
      />
  }
</div>
