import { Pipe, PipeTransform } from '@angular/core';
import { UserPreferences } from '../models/user-preferences.model';
import { CurrencyPipe } from '@angular/common';

const MAX_AMOUNT_LENGTH = 12;

@Pipe({
  name: `currencyDecimal`,
  standalone: true,
})
export class CurrencyDecimalPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(
    amount: number,
    userPreferences: UserPreferences,
    stablecoins: string[],
    fiats: string[],
    display = `symbol`,
    currencyCode = `EUR`,
    digits?: string
  ): string {
    let decimals: number;
    const isFIAT: boolean = fiats?.includes(currencyCode);

    if (!digits) {
      if (isFIAT) {
        decimals = userPreferences.numberOfDecimalsForFiat;
      } else {
        decimals = stablecoins?.includes(currencyCode)
          ? userPreferences.numberOfDecimalsForStable
          : userPreferences.numberOfDecimalsForCrypto;
      }

      digits = `1.${decimals.toString()}-${decimals.toString()}`;
    }

    if (userPreferences.language === `en` && display === `code`) {
      currencyCode = currencyCode + ` `;
    }

    const res: string = this.currencyPipe.transform(amount, currencyCode, display, digits, userPreferences.language);

    if (res.includes(`E`) || res.includes(`e`)) {
      return amount.toLocaleString(`fullwide`, { useGrouping: false });
    } else {
      return res;
    }
  }
}
