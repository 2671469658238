<div
  class="assessment-status"
  [ngClass]="{
    'assessment-status--finished': assessmentStatus === 'COMPLETED',
    'assessment-status--processing': assessmentStatus === 'IN_PROGRESS' || assessmentStatus === 'RETRIEVING_TX',
    'assessment-status--aborted': assessmentStatus === 'NEED_PLAN_UPGRADE',
    'assessment-status--empty': assessmentStatus === 'NEED_ACCOUNT_ADD',
  }"
  (click)="statusClick.emit()"
  >
  @if (assessmentStatus === 'COMPLETED') {
    <img src="/assets/img/icons/success-check.svg" alt="" />
    <div class="assessment-completed">
      <p class="body-12-med">{{ 'REPORT_UPDATED' | translate }}</p>
      @if (assessment.completed) {
        <p class="body-12">
          {{ assessment.completed | date : 'dd/MM/yyyy à HH:mm' }}
        </p>
      }
    </div>
  }

  @if (assessmentStatus === 'IN_PROGRESS') {
    <img class="rotating" src="/assets/img/icons/report-processing.svg" alt="" />
    <div>
      <p class="body-12-med">{{ 'REPORT_IN_PROGRESS' | translate }}</p>
      @if (assessmentETA > 0) {
        <p class="eta body-12">
          {{ formatETA(assessmentETA) }} {{ 'REMAINING_F' | translate }}
        </p>
      }
    </div>
  }

  @if (assessmentStatus === 'RETRIEVING_TX') {
    <img class="rotating" src="/assets/img/icons/report-processing.svg" alt="" />
    <p class="body-12-med">{{ 'RETRIEVING_TX' | translate }}</p>
  }

  @if (assessmentStatus === 'NEED_PLAN_UPGRADE') {
    <img src="/assets/img/icons/lock.svg" alt="" />
    <p class="body-12-med">{{ 'UPGRADE_TO_NEXT_PLAN' | translate }}</p>
    <img src="/assets/img/icons/next-black.svg" alt="" />
  }

  @if (assessmentStatus === 'NEED_ACCOUNT_ADD') {
    <img src="/assets/img/icons/info-banner.svg" alt="" />
    <p class="body-12-med">{{ 'ADD_ACCOUNTS_TO_START' | translate }}</p>
    <img src="/assets/img/icons/next-black.svg" alt="" />
  }
</div>
