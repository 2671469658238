import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromAuth from '../../authentication/store/selectors/authentication.selector';
import * as fromShared from '../../shared/store/selectors/shared.selector';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { User } from '../../taxation/models/user.model';
import { Feature } from '../../shared/models/feature.model';
import { FeatureService } from '../../shared/services/feature.service';

export const dashboardGuard: CanActivateFn = (route, state) => {
  const authStore$ = inject(Store<fromAuth.State>);
  const sharedStore$ = inject(Store<fromShared.State>);
  const featureService = inject(FeatureService);

  return combineLatest([
    authStore$.select(fromAuth.selectUser),
    sharedStore$.select(fromShared.selectFeatures(`dashboard`)),
  ]).pipe(
    filter(([user, features]: [User, Feature[]]) => !!user && !!features),
    switchMap(([user, features]: [User, Feature[]]) => {
      return featureService.isCustomersListFeatureEnabled(features[0], user.email).pipe(
        map((res: boolean) => res)
      );
    })
  );
};
