import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionsBreakdownTooltipComponent } from '../../../taxation/components/transactions-breakdown-tooltip/transactions-breakdown-tooltip.component';
import { LegalFormsComponent } from '../../../taxation/pages/legal-forms-page/legal-forms-page.component';
import {
  loadAvailableAddonsAction,
  loadAvailablePlansAction,
  loadPlansByFiscalYearsAction,
  loadUpgradeFishingEstimateAction,
} from '../../../taxation/store/actions/payment.action';
import * as fromPayment from '../../../taxation/store/selectors/payment.selector';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { SidenavService } from '../../services/sidenav.service';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { UtilsService } from '../../services/utils.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { BetaProgramDialogComponent } from '../dialogs/beta-program-dialog/beta-program-dialog.component';
import { FeatureService } from '../../services/feature.service';
import { User } from '../../../taxation/models/user.model';
import { Feature } from '../../models/feature.model';

@Component({
  selector: `app-layout`,
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatButtonModule,
    MatProgressBarModule,
    MatMenuModule,
    MatListModule,
    MatDividerModule,
    TransactionsBreakdownTooltipComponent,
    SidenavComponent,
    LegalFormsComponent,
  ],
  templateUrl: `./layout.component.html`,
  styleUrls: [`./layout.component.scss`],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(`rightSidenav`) rightSidenav: MatSidenav;

  fiscalYear = new Date().getFullYear() - 1;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly sidenavService: SidenavService,
    private readonly utilsService: UtilsService,
    private readonly featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.paymentStore$.dispatch(loadAvailablePlansAction());
    this.paymentStore$.dispatch(loadAvailableAddonsAction());
    this.paymentStore$.dispatch(
      loadUpgradeFishingEstimateAction({
        fiscalYear: this.fiscalYear,
        checkoutType: `PLAN`,
        computeAssessmentStatus: true,
      })
    );
    this.paymentStore$.dispatch(loadPlansByFiscalYearsAction());

    combineLatest([
      this.authStore$.pipe(select(fromAuth.selectUser)),
      this.sharedStore$.pipe(select(fromShared.selectFeatures(`lab`))),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([user, features]: [User, Feature[]]) => {
          if (user && features?.length === 1) {
            const betaProgram = localStorage.getItem(`beta-program`);
            const openDialog =
              this.featureService.isRangedFeatureEnabled(features[0]) &&
              betaProgram === null &&
              user.fiscalResidency === `FR` &&
              user.plan !== `PLN000`;

            if (openDialog) {
              this.openBetaProgramDialog();
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.rightSidenav);
  }

  openBetaProgramDialog(): void {
    setTimeout(() => {
      const dialogRef = this.utilsService.openDialog(BetaProgramDialogComponent, `587px`, `auto`);

      dialogRef
        .afterClosed()
        .pipe(
          takeUntil(this.destroy$),
          map((res: boolean) => {
            if (!res) {
              localStorage.setItem(`beta-program`, `false`);
            }
          })
        )
        .subscribe();
    }, 10000);
  }
}
