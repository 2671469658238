/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: `root`,
})
export class TrackingService {
  loadGTM(): void {
    const gtmId = environment.gtmId;

    if (gtmId) {
      const gtmScript = document.createElement(`script`);
      const gtmNoScript = document.createElement(`noscript`);

      const gtmScript2 = document.createElement(`script`);
      const gtmNoScript2 = document.createElement(`noscript`);

      gtmScript.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${gtmId}');`;

      gtmNoScript.innerHTML = ` 
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
      ></iframe>`;

      gtmScript2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WGV5VX5');`;

      gtmNoScript2.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WGV5VX5"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.head.appendChild(gtmScript);
      document.head.appendChild(gtmScript2);
      document.body.appendChild(gtmNoScript);
      document.body.appendChild(gtmNoScript2);
    }
  }

  pushTag(tag: Tag): void {
    if (tag.environment_market) {
      switch (tag[`environment_market`]) {
        case `FR`:
          tag[`environment_market`] = `france`;
          break;
        case `BE`:
          tag[`environment_market`] = `belgium`;
          break;
        case `ES`:
          tag[`environment_market`] = `spain`;
          break;
      }
    }

    if (tag.ecommerce) {
      window[`dataLayer`].push({ ecommerce: null });
      window[`dataLayer`].push({
        event: tag.event,
        environment_market: tag.environment_market,
        ecommerce: {
          transaction_id: tag.ecommerce.transaction_id,
          value: tag.ecommerce.price,
          tax: +(tag.ecommerce.price - tag.ecommerce.price / 1.2).toFixed(2),
          currency: `EUR`,
          coupon: tag.ecommerce.coupon || ``,
          payment_type: tag.ecommerce.payment_type,
          items: [
            {
              item_id: tag.ecommerce.item_name?.toLowerCase() || `charge`,
              item_name: tag.ecommerce.item_name?.toLowerCase() || `charge`,
              currency: `EUR`,
              coupon: tag.ecommerce.coupon || ``,
              discount: tag.ecommerce.discount,
              price: tag.ecommerce.price,
              quantity: 1,
            },
          ],
        },
      });
    } else {
      window[`dataLayer`].push(tag);
    }
  }
}
