import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from '../../store/selectors/shared.selector';
import { Subject, takeUntil, tap } from 'rxjs';
import { goToAction } from '../../store/actions/shared.action';

@Component({
  standalone: true,
  templateUrl: `./referral.component.html`,
})
export class ReferralComponent implements OnInit, OnDestroy {
  referralCode: string;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sharedStore$: Store<fromShared.State>
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        tap((params: ParamMap) => {
          this.referralCode = params.get(`referralCode`);

          if (this.referralCode) {
            localStorage.setItem(`referralCode`, this.referralCode);
            localStorage.setItem(`auth_callback_url`, `/accounts`);

            this.sharedStore$.dispatch(goToAction({ url: `/accounts` }));
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
