import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { Assessment } from '../../../taxation/models/assessment.model';
import { UserPlan } from '../../../taxation/models/user.model';
import { PLANS } from '../../../taxation/constants/plans.constant';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiscalYearPlanEstimate } from '../../../taxation/models/payment.model';

@Component({
  selector: `app-plan-status`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, MatTooltipModule],
  templateUrl: `./plan-status.component.html`,
  styleUrls: [`./plan-status.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStatusComponent implements OnChanges, AfterViewInit {
  planLabel = ``;
  maximumNbOfTransactions: number;
  transactionsCount = 0;
  planStatusExplanation: string;
  progress = 0;

  @Input() set plan(plan: UserPlan) {
    this._plan = plan;
    this.maximumNbOfTransactions = PLANS.get(plan)?.maximumNbOfTransactions;
  }

  get plan(): UserPlan {
    return this._plan;
  }

  @Input() assessment: Assessment;
  @Input() associatedFiscalYear: number;
  @Input() language: string;
  @Input() plansByFiscalYears: Map<string, FiscalYearPlanEstimate>;

  @Output() upgradePlan: EventEmitter<void> = new EventEmitter<void>();
  @Output() upgradeAddon: EventEmitter<void> = new EventEmitter<void>();

  private _plan: UserPlan;

  constructor(private readonly translateService: TranslateService) {}

  ngOnChanges(): void {
    this.updateTransactionsCount();
    this.updatePlanProgress();
    this.updateTranslation();
  }

  ngAfterViewInit(): void {
    this.updatePlanProgress();
  }

  updatePlanProgress(): void {
    const transactionsCount = this.transactionsCount;
    let progress = (transactionsCount / this.maximumNbOfTransactions) * 100;
    progress = progress > 100 ? 100 : progress;

    this.progress = progress;
  }

  updateTransactionsCount(): void {
    if (this.associatedFiscalYear === this.assessment?.fiscalYear) {
      this.transactionsCount = this.plansByFiscalYears?.get(this.associatedFiscalYear.toString())?.numberOfTransactions;
    }
  }

  updateTranslation(): void {
    this.planLabel = this.translateService
      .instant(`PLAN_SHORT`)
      .replace(`{plan}`, this.translateService.instant(`PLANS.${this.plan}`));
    this.planStatusExplanation = this.translateService
      .instant(`PLAN_STATUS_EXPLANATION`)
      .replace(`{fiscalYear}`, this.associatedFiscalYear.toString());
  }
}
