import { Component, Input, ViewChild } from '@angular/core';

import { TaxableCession } from '../../models/taxable-cession.model';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-spanish-taxable-cession`,
  standalone: true,
  imports: [MatDividerModule, MatTooltipModule, TranslateModule],
  templateUrl: `./spanish-taxable-cession.component.html`,
  styleUrls: [`./spanish-taxable-cession.component.scss`],
})
export class SpanishTaxableCessionComponent {
  @ViewChild(`tooltip0`) tooltip0: MatTooltip;
  @ViewChild(`tooltip1`) tooltip1: MatTooltip;
  @ViewChild(`tooltip2`) tooltip2: MatTooltip;
  @ViewChild(`tooltip3`) tooltip3: MatTooltip;
  @ViewChild(`tooltip4`) tooltip4: MatTooltip;


  @Input() taxableCession: TaxableCession;

  tooltipLabel = `COPY`;
  copied = false;

  constructor(public readonly clipboardService: ClipboardService) {}

  copyValue(value: string, tooltipRef: string): void {
    this.copied = true;
    this.tooltipLabel = `COPIED`;
    this.clipboardService.copyFromContent(value);
    this[tooltipRef]?.show();
  }

  resetCopied(tooltipRef: string): void {
    this.copied = false;
    this.tooltipLabel = `COPY`;
    this[tooltipRef]?.hide();
  }
}
