import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as insightReducer from '../reducers/insight.reducer';
import { MarketHistory } from '../../models/market.model';

export const INSIGHT_FEATURE_NAME = `insight`;

export interface State {
  insight: insightReducer.State;
}

export const selectInsightState: MemoizedSelector<State, insightReducer.State> =
  createFeatureSelector<insightReducer.State>(INSIGHT_FEATURE_NAME);

export const selectMarketHistory: MemoizedSelector<State, MarketHistory> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.marketHistory
);

export const selectMarketFeed: MemoizedSelector<State, any> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.marketFeed
);

export const selectLiveBalances: MemoizedSelector<State, any> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.liveBalances
);
