/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { MarketFeed, MarketHistory } from '../../models/market.model';
import { LiveBalanceResponse } from '../../models/live-balance.model';

// eslint-disable-next-line no-shadow
export enum InsightActions {
  StartFeedStream = `[Insight] Start Feed Stream`,
  StopFeedStream = `[Insight] Stop Feed Stream`,
  SetMarketFeed = `[Insight] Set Market Feed`,
  LoadMarketHistory = `[Insight] Load Market History`,
  SetMarketHistory = `[Insight] Set Market History`,
  LoadLiveBalances = `[Insight] Load Live Balances`,
  SetLiveBalances = `[Insight] Set Live Balances`,
}

export const startFeedStreamAction = createAction(InsightActions.StartFeedStream);

export const stopFeedStreamAction = createAction(InsightActions.StopFeedStream);

export const setMarketFeedAction = createAction(InsightActions.SetMarketFeed, props<{ marketFeed: MarketFeed }>());

export const loadMarketHistoryAction = createAction(InsightActions.LoadMarketHistory);

export const setMarketHistoryAction = createAction(
  InsightActions.SetMarketHistory,
  props<{ marketHistory: MarketHistory }>()
);

export const loadLiveBalancesAction = createAction(InsightActions.LoadLiveBalances);

export const setLiveBalancesAction = createAction(
  InsightActions.SetLiveBalances,
  props<{ liveBalances: LiveBalanceResponse }>()
);
