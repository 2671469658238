import { Form3916BisPlatformInfos } from '../../shared/models/platform-adress.model';

export const FORM_3916_BIS_PLATFORMS: Map<string, Form3916BisPlatformInfos> = new Map([
  [
    `ASCENDEX`,
    {
      designation: `AscendEx`,
      country: `Singapour`,
      additionalAddress: `2 CT Hub 2`,
      street: `114 Lavender Street`,
      zipCode: `09 88`,
      url: `https://ascendex.com/en/global-digital-asset-platform`,
    },
  ],
  [
    `BINANCE`,
    {
      designation: `Binance Europe Services Limited`,
      country: `Malte`,
      additionalAddress: ``,
      street: `75 ABATE RIGORD STREET`,
      zipCode: `TA XBIEXXBX 1120`,
      url: `https://binance.com`,
    },
  ],
  [
    `BITFINEX`,
    {
      designation: `iFinex Inc`,
      country: `Territoire du RU aux Antilles`,
      additionalAddress: `Third Floor,`,
      street: `Road Town, VG1110,`,
      zipCode: ``,
      url: `https://www.bitfinex.com`,
    },
  ],
  [
    `BITMEX`,
    {
      designation: `HDR Global Trading Limited`,
      country: `SEYCHELLES`,
      additionalAddress: `Global Gateway`,
      street: `8 rue de la Perle`,
      zipCode: `Providence`,
      url: `https://www.bitmex.com`,
    },
  ],
  [
    `BITMART`,
    {
      designation: `Bitmart`,
      country: `TERRITOIRE DU RU Cayman Islands`,
      additionalAddress: `23 Lime Tree Bay Avenue`,
      street: ``,
      zipCode: `KY1 1110 KY`,
      url: `https://www.bitmart.com`,
    },
  ],
  [
    `BITPANDA`,
    {
      designation: `Bitpanda GmbH`,
      country: `Autriche`,
      additionalAddress: `Stella Klein Low Weg 17`,
      street: ``,
      zipCode: `1020 Vienna`,
      url: `https://www.bitpanda.com`,
    },
  ],
  [
    `BITSTAMP`,
    {
      designation: `Bitstamp Limited`,
      country: `Royaume Uni`,
      additionalAddress: `5 New Street Square,`,
      street: ``,
      zipCode: `Londres EC4A`,
      url: `https://www.bitstamp.net`,
    },
  ],
  [
    `BITTREX`,
    {
      designation: `Bittrex International GmbH`,
      country: `United States of America`,
      additionalAddress: ``,
      street: `800 5th Ave Seattle`,
      zipCode: `WA 98104`,
      url: `https://global.bittrex.com`,
    },
  ],
  [
    `BITVAVO`,
    {
      designation: `Bitvavo B.V.`,
      country: `The Netherlands`,
      additionalAddress: ``,
      street: `Keizersgracht 281 Amsterdam North Holland`,
      zipCode: `1016 ED NL`,
      url: `https://bitvavo.com/`,
    },
  ],
  [
    `BLOCKFI`,
    {
      designation: `BlockFi Bitcoin Trust`,
      country: `Etats Unis`,
      additionalAddress: `SUITE 263`,
      street: `201 MONTGOMERY STREET`,
      zipCode: `New Jersey 07302`,
      url: `https://blockfi.com`,
    },
  ],
  [
    `BYBIT`,
    {
      designation: `Bybit Fintech Limited`,
      country: `Grande Bretagne`,
      additionalAddress: ``,
      street: `128 City Road London`,
      zipCode: `EC1V 2NX`,
      url: `https://www.bybit.com`,
    },
  ],
  [
    `CELSIUS`,
    {
      designation: `Celsius Network Lending`,
      country: `Etats Unis`,
      additionalAddress: `9TH FL`,
      street: `221 RIVER ST`,
      zipCode: `HOBOKEN NJ 07030`,
      url: `https://celsius.network.com`,
    },
  ],
  [
    `COINBASE_PRO`,
    {
      designation: `Coinbase Inc.`,
      country: `Irlande`,
      additionalAddress: ``,
      street: `70 Sir John Rogersons Quay`,
      zipCode: `D02 R296 Dublin`,
      url: `https://pro.coinbase.com`,
    },
  ],
  [
    `COINBASE`,
    {
      designation: `Coinbase, Inc.`,
      country: `Irlande`,
      additionalAddress: ``,
      street: `70 Sir John Rogersons Quay`,
      zipCode: `D02 R296 Dublin`,
      url: `https://www.coinbase.com`,
    },
  ],
  [
    `COINBASE_PRIME`,
    {
      designation: `Coinbase, Inc.`,
      country: `EtatsUnis`,
      additionalAddress: `Suite 1250`,
      street: `100 Pine Street`,
      zipCode: `San Francisco CA 94111`,
      url: `https://primebroker.coinbase.com`,
    },
  ],
  [
    `COINBENE`,
    {
      designation: `CoinBene`,
      country: `SINGAPOUR`,
      additionalAddress: ``,
      street: `1 Coleman Street  Adelphi`,
      zipCode: `Adelphi 179803`,
      url: `https://www.coinbene.com`,
    },
  ],
  [
    `COINLIST`,
    {
      designation: `Coinlist`,
      country: `USA`,
      additionalAddress: ``,
      street: `850 Montgomery St`,
      zipCode: `350 San Francisco`,
      url: `https://coinlist.co`,
    },
  ],
  [
    `COINLIST_PRO`,
    {
      designation: `Coinlist Pro`,
      country: `USA`,
      additionalAddress: ``,
      street: `850 Montgomery St`,
      zipCode: `350 San Francisco`,
      url: `https://pro.coinlist.co`,
    },
  ],
  [
    `COSS`,
    {
      designation: `COSS Asset Management Limited`,
      country: `SINGAPOUR`,
      additionalAddress: `1 Clementi Loop, 06-01 Singapore`,
      street: ``,
      zipCode: `Singapore 129808 SGa`,
      url: `https://www.coss.io`,
    },
  ],
  [
    `CREX24`,
    {
      designation: `Crex24`,
      country: `SEYCHELLES`,
      additionalAddress: `Level 7 Spinola Park Triq Mikiel Ang Borg`,
      street: ``,
      zipCode: `St Julians SPK 1000`,
      url: `https://crex.exchange/`,
    },
  ],
  [
    `CRYPTO_COM`,
    {
      designation: `Cryptocom`,
      country: `Malte`,
      additionalAddress: `Level 7 Spinola Park Triq Mikiel Ang Borg`,
      street: ``,
      zipCode: `St Julians SPK 1000`,
      url: `https://crypto.com`,
    },
  ],
  [
    `ETORO`,
    {
      designation: `eToro Europe Ltd`,
      country: `Chypre`,
      additionalAddress: `Kanika Business Center`,
      street: `4 Profiti Ilia Germasogeia`,
      zipCode: `4046 Cyprus`,
      url: `https://www.etoro.com`,
    },
  ],
  [
    `FTX`,
    {
      designation: `FTX Trading LTD`,
      country: `antigua et barbuda`,
      additionalAddress: `Lower Factory Road`,
      street: ` ST Johns`,
      zipCode: ``,
      url: `https://ftx.com`,
    },
  ],
  [
    `GATE_IO`,
    {
      designation: `Gate.io`,
      country: `TERRITOIRE DU RU`,
      additionalAddress: ``,
      street: `Cardinal Avenue George Town Grand Cayman`,
      zipCode: `KY1 1205`,
      url: `https://www.gate.io/fr`,
    },
  ],
  [
    `HITBTC`,
    {
      designation: `HitBTC`,
      country: `Chine`,
      additionalAddress: `Unit 19 7F One Midtown No11 Hoi Shing Road`,
      street: ``,
      zipCode: `Tsuen Wan New Territories Hong Kong`,
      url: `https://hitbtc.com`,
    },
  ],
  [
    `HOTBIT`,
    {
      designation: `HotBit`,
      country: `Chine`,
      additionalAddress: `200120`,
      street: `Central Ave`,
      zipCode: ``,
      url: `https://www.hotbit.io/`,
    },
  ],
  [
    `HUOBI`,
    {
      designation: `Huobi Global Ltd`,
      country: `Singapour`,
      additionalAddress: `Asia Square Tower 1`,
      street: `8 Marina View, Central Business District`,
      zipCode: `018961, Singapour`,
      url: `https://www.huobi.com`,
    },
  ],
  [
    `KRAKEN`,
    {
      designation: `Kraken PAYWARD LTD`,
      country: `Royaume Uni`,
      additionalAddress: `6th Floor One London Wall`,
      street: ``,
      zipCode: `London EC2Y 5EB`,
      url: `https://www.kraken.com`,
    },
  ],
  [
    `KUCOIN`,
    {
      designation: `Kucoin`,
      country: `SEYCHELLES`,
      additionalAddress: `Suite 23 Floor 1 Eden Plaza Eden Island Mahe`,
      street: ``,
      zipCode: ``,
      url: `https://www.kucoin.com`,
    },
  ],
  [
    `LIQUID`,
    {
      designation: `Liquid Group`,
      country: `Singapour`,
      additionalAddress: ``,
      street: `1 Fusionopolis View`,
      zipCode: `07-02 Sandcrawler,`,
      url: `https://www.liquid.com`,
    },
  ],
  [
    `NEXO`,
    {
      designation: `Nexo Services`,
      country: `Royaume Uni`,
      additionalAddress: `CANARY WHARF`,
      street: `1 CANADA SQUARE FLOOR 39`,
      zipCode: `E14 5AB LONDON`,
      url: `https://nexo.io`,
    },
  ],
  [
    `PAYEER`,
    {
      designation: `PAYCORP LIMITED`,
      country: `Lithuanie`,
      additionalAddress: `Vilnius`,
      street: `Eisiskiu Sodu 18-oji g.11`,
      zipCode: ``,
      url: `https://payeer.com`,
    },
  ],
  [
    `POLONIEX`,
    {
      designation: `Polo Digital Assets Ltd`,
      country: `SEYCHELLES`,
      additionalAddress: `F20 1st Floor Eden Plaza`,
      street: ``,
      zipCode: ``,
      url: `https://poloniex.com`,
    },
  ],
  [
    `REVOLUT`,
    {
      designation: `Revolut Bank UAB`,
      country: `Lituanie`,
      additionalAddress: `Konstitucijos ave 21B`,
      street: ``,
      zipCode: `08130 Vilnius`,
      url: `https://www.revolut.com`,
    },
  ],
  [
    `SWISSBORG`,
    {
      designation: `SBorg SA SwissBorg Solutions`,
      country: `Suisse`,
      additionalAddress: ``,
      street: `8 rue du GrandChêne`,
      zipCode: `1003 Lausanne`,
      url: `https://swissborg.com`,
    },
  ],
  [
    `UPHOLD`,
    {
      designation: `Uphold Europe Limited`,
      country: `Etats-Unis`,
      additionalAddress: `Uphold Europe Limited Suite A `,
      street: `6 W 18th Street 3rd Floor`,
      zipCode: ` New York 10011 US`,
      url: `https://uphold.com`,
    },
  ],
  [
    `VIRWOX`,
    {
      designation: `Société fermée`,
      country: `Société fermée`,
      additionalAddress: `Société fermée`,
      street: `Société fermée`,
      zipCode: `Société fermée`,
      url: `https://www.virwox.com/?language=fr`,
    },
  ],
  [
    `WIREX`,
    {
      designation: `Wirex Limited`,
      country: `Royaume-Uni`,
      additionalAddress: ``,
      street: `34 37 Liverpool St`,
      zipCode: `London EC2M7PP`,
      url: `https://wirexapp.com`,
    },
  ],
  [
    `YIELD`,
    {
      designation: `Yield.app SRL`,
      country: `Italy`,
      additionalAddress: ``,
      street: `via Giulio, Caccini 1`,
      zipCode: `Cap 00198 ROMA`,
      url: `https://www.yield.app/`,
    },
  ],
  [
    `YOUHODLER`,
    {
      designation: `YouHodler`,
      country: `Switzerland`,
      additionalAddress: `Arch Makariou III 172`,
      street: `Avenue du Théatre 7`,
      zipCode: `VAUD, 1005`,
      url: `https://fr.youhodler.com/`,
    },
  ],
  [
    `COINMERCE`,
    {
      designation: `Coinmerce BV.`,
      country: `The Netherlands`,
      additionalAddress: ``,
      street: `Beechavenue 122`,
      zipCode: `1119 PR Schiphol Rijk`,
      url: `https://coinmerce.io/`,
    },
  ],
  [
    `COINMETRO`,
    {
      designation: `Coinmetro OU`,
      country: `ESTONIE`,
      additionalAddress: `Tartu mnt 84a402`,
      street: ``,
      zipCode: `Tallinn 10112`,
      url: `https://coinmetro.com/`,
    },
  ],
  [
    `PROBIT`,
    {
      designation: `ProBit`,
      country: `Seychelles`,
      additionalAddress: `Suite 9 Ansuya Estate`,
      street: `Revolution Avenue`,
      zipCode: `Victoria Mahe`,
      url: `https://www.probit.com/fr-fr/`,
    },
  ],
  [
    `COINZOOM`,
    {
      designation: `CoinZoom`,
      country: `United States`,
      additionalAddress: `Suite 206`,
      street: `4640 S Holladay Village Plaza,`,
      zipCode: `Salt Lake City UT 84117`,
      url: `https://www.coinzoom.com/`,
    },
  ],
  [
    `CRYPTO_COM_APP`,
    {
      designation: `Cryptocom app`,
      country: `MALTA`,
      additionalAddress: `Level 7 Spinola Park Triq Mikiel Ang Borg`,
      street: ``,
      zipCode: `St Julians SPK 1000`,
      url: `https://crypto.com`,
    },
  ],
  [
    `OKEX`,
    {
      designation: `OKX`,
      country: `Malaisie`,
      additionalAddress: `Unit 10-02, Level 10 Menara Binjai No2 Jalan Binjai`,
      street: ``,
      zipCode: `50450 Kuala Lumpur`,
      url: `https://www.okx.com/fr`,
    },
  ],
  [
    `CAKEDEFI`,
    {
      designation: `Bake`,
      country: `Singapour`,
      additionalAddress: ``,
      street: `71 Robinson Rd, #15-106`,
      zipCode: `St Julians SPK 1000`,
      url: `https://bake.io/`,
    },
  ],
  [
    `CEX_IO`,
    {
      designation: `CEX.IO`,
      country: `Lituanie`,
      additionalAddress: ``,
      street: `Didžioji str. 18, Vilnius`,
      zipCode: ``,
      url: `https://cex.io/`,
    },
  ],
  [
    `WALLET_BLOCKCHAIN_COM`,
    {
      designation: `WALLET_BLOCKCHAIN_COM`,
      country: `Royaume-Uni`,
      additionalAddress: ``,
      street: `86-90 Paul Street London`,
      zipCode: `EC2A 4NE GB`,
      url: `https://www.blockchain.com/`,
    },
  ],
  [
    `CRYPTOPAY`,
    {
      designation: `CPS Solutions OÜ`,
      country: `Royaume Uni`,
      additionalAddress: ``,
      street: `8 Devonshire Square, Spitalfields`,
      zipCode: `London`,
      url: `https://cryptopay.me/fr-FR`,
    },
  ],
  [
    `FAIRMINT`,
    {
      designation: `FAIRMINT`,
      country: `Etats-Unis`,
      additionalAddress: ``,
      street: `8 The Green STE 300 Dover`,
      zipCode: `Dover`,
      url: `https://www.fairmint.com/`,
    },
  ],
  [
    `LIVECOIN`,
    {
      designation: `LIVECOIN`,
      country: `États Unis`,
      additionalAddress: ``,
      street: `The Vue 255 w 5th st San Pedro`,
      zipCode: `California 90731 US`,
      url: `https://www.livecoinwatch.com/`,
    },
  ],
  [
    `NICEHASH`,
    {
      designation: `NICEHASH`,
      country: `SLOVENIE`,
      additionalAddress: ``,
      street: `Maribor Maribor 2000 SI`,
      zipCode: ``,
      url: `https://www.nicehash.com/`,
    },
  ],
  // [
  //   `TRADE_REPUBLIC`,
  //   {
  //     designation: `Trade Republic Bank GmbH`,
  //     country: `Allemagne`,
  //     additionalAddress: ``,
  //     street: `Köpenicker Straße 40c`,
  //     zipCode: `10179 Berlin`,
  //     url: `https://traderepublic.com/fr-fr`,
  //   },
  // ],
  [
    `MEXC`,
    {
      designation: `MEXC`,
      country: `Seychelles`,
      additionalAddress: `Sertus Chambers, Second Floor`,
      street: `The Quadrant Manglier Street Victoria`,
      zipCode: ``,
      url: `https://www.mexc.com/fr-FR`,
    },
  ],
  [
    `BITGET`,
    {
      designation: `BITGET`,
      country: `TERRITOIRE DU RU Cayman Islands`,
      additionalAddress: `Harbour Place GEORGE TOWN`,
      street: `Harneys Fiduciary Limited 4th Floor`,
      zipCode: `GRAND CAYMAN KY1 1205`,
      url: `https://www.bitget.com/fr/`,
    },
  ],
]);

export const FORM_2086_CESSIONS_DEMO: any[] = [
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
];
