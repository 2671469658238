<div class="container">
  <div class="f-row width-90">
    <p class="body-14-med">Denominación de la modena virtual</p>

    <div class="box box--primary" (click)="copyValue(taxableCession['0001'], 'tooltip0')" (mouseleave)="resetCopied('tooltip0')">
      <p class="body-14-med">{{ taxableCession['0001'] }}</p>

      <div
        #tooltip0="matTooltip"
        class="copy pointer"
        [matTooltip]="tooltipLabel | translate"
        matTooltipPosition="left"
      ></div>
    </div>
  </div>
  <div class="f-row width-90">
    <p class="body-14-med">Indique el tipo de contraprestación recibida a cambio</p>

    <div class="box box--dropdown">
      <p class="body-14-med">N</p>
      <mat-divider class="divider" vertical></mat-divider>
      <img src="/assets/img/icons/polygon.svg" alt="" />
    </div>
  </div>
  <div class="f-row width-90">
    <p class="body-14-bold">Valor de transmisión</p>

    <div class="separator"></div>
    <div class="box">
      <p class="body-14-med">{{ taxableCession['1633'] }}</p>
    </div>
    <div class="pen-box"></div>
  </div>
  <mat-divider class="connector" vertical></mat-divider>
  <div class="details">
    <div class="f-row">
      <p class="body-14-med">Importe de la transmisión</p>

      <div class="separator"></div>
      <div class="box box--primary" (click)="copyValue(taxableCession['1633a'], 'tooltip1')" (pointerleave)="resetCopied('tooltip1')">
        <p class="body-14-med">{{ taxableCession['1633a'] }}</p>
        <div
          #tooltip1="matTooltip"
          class="copy pointer"
          [matTooltip]="tooltipLabel | translate"
          matTooltipPosition="left"
        ></div>
      </div>
    </div>
    <div class="f-row">
      <p class="body-14-med">Gastos de la transmisión</p>

      <div class="separator"></div>
      <div class="box box--primary" (click)="copyValue(taxableCession['1633b'], 'tooltip2')" (mouseleave)="resetCopied('tooltip2')">
        <p class="body-14-med">{{ taxableCession['1633b'] }}</p>
        <div
          #tooltip2="matTooltip"
          class="copy pointer"
          [matTooltip]="tooltipLabel | translate"
          matTooltipPosition="left"
        ></div>
      </div>
    </div>
  </div>

  <div class="f-row width-90">
    <p class="body-14-bold">Valor de adquisición</p>

    <div class="separator"></div>
    <div class="box">
      <p class="body-14-med">{{ taxableCession['1637'] }}</p>
    </div>
    <div class="pen-box"></div>
  </div>
  <mat-divider class="connector" vertical></mat-divider>
  <div class="details">
    <div class="f-row">
      <p class="body-14-med">Importe de la adquisición</p>

      <div class="separator"></div>
      <div class="box box--primary" (click)="copyValue(taxableCession['1637a'], 'tooltip3')" (pointerleave)="resetCopied('tooltip3')">
        <p class="body-14-med">{{ taxableCession['1637a'] }}</p>
        <div
          #tooltip3="matTooltip"
          class="copy pointer"
          [matTooltip]="tooltipLabel | translate"
          matTooltipPosition="left"
        ></div>
      </div>
    </div>
    <div class="f-row">
      <p class="body-14-med">Gastos de la adquisición</p>

      <div class="separator"></div>
      <div class="box box--primary" (click)="copyValue(taxableCession['1637b'], 'tooltip4')" (pointerleave)="resetCopied('tooltip4')">
        <p class="body-14-med">{{ taxableCession['1637b'] }}</p>
        <div
          #tooltip4="matTooltip"
          class="copy pointer"
          [matTooltip]="tooltipLabel | translate"
          matTooltipPosition="left"
        ></div>
      </div>
    </div>
  </div>
</div>
