import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: `root`,
})
export class ToastService {
  constructor(private readonly snackbar: MatSnackBar) {}

  success(message: string, duration = 3000): void {
    this.snackbar.openFromComponent(ToastComponent, {
      data: {
        type: `success`,
        message,
      },
      horizontalPosition: `center`,
      verticalPosition: `bottom`,
      duration,
      panelClass: [`custom-toast`],
    });
  }

  error(message: string, duration = 3000): void {
    this.snackbar.openFromComponent(ToastComponent, {
      data: {
        type: `error`,
        message,
      },
      horizontalPosition: `center`,
      verticalPosition: `bottom`,
      duration,
      panelClass: [`custom-toast`],
    });
  }

  open(message: string, duration = 3000): void {
    this.snackbar.openFromComponent(ToastComponent, {
      data: {
        type: `info`,
        message,
      },
      horizontalPosition: `center`,
      verticalPosition: `bottom`,
      duration,
      panelClass: [`custom-toast`],
    });
  }

  dismiss(): void {
    this.snackbar.dismiss();
  }
}
