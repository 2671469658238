import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Scam } from '../models/scam.model';

@Injectable({
  providedIn: `root`,
})
export class ScamService {
  constructor(private http: HttpClient) {}

  getUserScamList(): Observable<Scam[]> {
    return this.http.get<Scam[]>(`${environment.apiUrl}/v1/tax/user/scam`);
  }

  addUserScam(scam: Scam): Observable<Scam[]> {
    return this.http.post<Scam[]>(
      `${environment.apiUrl}/v1/tax/user/scam?symbol=${scam.symbol}&address=${scam.address}&platform=${scam.platform}`,
      {}
    );
  }

  deleteUserScam(scam: Scam): Observable<Scam[]> {
    return this.http.delete<Scam[]>(
      `${environment.apiUrl}/v1/tax/user/scam?symbol=${scam.symbol}&address=${scam.address}&platform=${scam.platform}`
    );
  }
}
