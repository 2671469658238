/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { MarketFeed, MarketHistory } from '../../models/market.model';
import { BalanceService } from '../../services/balance.service';
import { FeedService } from '../../services/feed.service';
import { InsightService } from '../../services/insight.service';
import {
  loadLiveBalancesAction,
  loadMarketHistoryAction,
  setLiveBalancesAction,
  setMarketFeedAction,
  setMarketHistoryAction,
  startFeedStreamAction,
  stopFeedStreamAction,
} from '../actions/insight.action';
import * as fromInsight from '../selectors/insight.selector';

@Injectable()
export class InsightEffects {
  startFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof startFeedStreamAction>>(startFeedStreamAction),
        withLatestFrom(this.authStor$.pipe(select(fromAuth.selectAccessToken))),
        switchMap(([action, accessToken]: [ReturnType<typeof startFeedStreamAction>, string]) => {
          const eventSource: EventSource = this.feedService.startFeed(accessToken);

          eventSource.onmessage = (message: MessageEvent): void => {
            const marketFeed: MarketFeed = JSON.parse(message.data);
            this.insightStore$.dispatch(setMarketFeedAction({ marketFeed }));
          };

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  stopFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof stopFeedStreamAction>>(stopFeedStreamAction),
        switchMap(() => {
          this.feedService.stopFeed();

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadMarketHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadMarketHistoryAction>>(loadMarketHistoryAction),
      switchMap(() => {
        return this.insightService
          .getDashboardHistory()
          .pipe(map((marketHistory: MarketHistory) => setMarketHistoryAction({ marketHistory })));
      })
    )
  );

  loadLiveBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLiveBalancesAction>>(loadLiveBalancesAction),
      switchMap(() => {
        return this.balanceService.getLiveBalances().pipe(
          map((liveBalances: LiveBalanceResponse) => {
            return setLiveBalancesAction({ liveBalances });
          })
        );
      })
    )
  );

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly authStor$: Store<fromAuth.State>,
    private readonly actions$: Actions,
    private readonly feedService: FeedService,
    private readonly insightService: InsightService,
    private readonly balanceService: BalanceService
  ) {}
}
