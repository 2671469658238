import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { createWidget } from '@typeform/embed';
import { CloseButtonComponent } from '../../close-button/close-button.component';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../../../authentication/store/selectors/authentication.selector';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from '../../../../taxation/models/user.model';

@Component({
  selector: `app-typeform-dialog`,
  standalone: true,
  imports: [CloseButtonComponent],
  templateUrl: `./typeform-dialog.component.html`,
  styleUrls: [`./typeform-dialog.component.scss`],
})
export class TypeformDialogComponent implements OnInit, OnDestroy {
  email: string;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<TypeformDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { typeformId: string },
    private readonly authStore$: Store<fromAuth.State>
  ) {}

  ngOnInit(): void {
    this.authStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectUser),
        map((user: User) => {
          if (user) {
            this.email = user.email;
            this.createTypeformWidget(this.data.typeformId);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createTypeformWidget(typeformId: string): void {
    createWidget(typeformId, {
      container: document.querySelector(`#form`),
      transitiveSearchParams: [`email`],
      hidden: {
        email: this.email,
      },
      onSubmit: ({ formId, responseId }) => {
        localStorage.setItem(`beta-program`, `true`);

        setTimeout(() => {
          this.dialogRef.close();
        }, 5000);
      },
    });
  }

  closeDialog(): void {
    localStorage.setItem(`beta-program`, `false`);
    this.dialogRef.close();
  }
}
