import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { Account, AccountListResponse } from '../../../taxation/models/account.model';
import { environment } from '../../../../environments/environment';
import { Configuration } from '../../models/configuration.model';
import { Feature } from '../../models/feature.model';
import { TransferRequest } from '../../models/transfer-request.model';
import { UserPreferences } from '../../models/user-preferences.model';
import * as sharedReducer from '../reducers/shared.reducer';
import { GeolocationDetails } from '../../../taxation/models/geolocation-details.model';
import { Balance } from '../../../taxation/models/balance.model';
import { CustomError } from '../../models/error.model';
import { Scam } from '../../models/scam.model';
import { ReportedToken } from '../../models/reported-token.model';

export const SHARED_FEATURE_NAME = `shared`;

export interface State {
  shared: sharedReducer.State;
}

export const selectSharedState: MemoizedSelector<State, sharedReducer.State> =
  createFeatureSelector<sharedReducer.State>(SHARED_FEATURE_NAME);

export const selectAllFeatures: MemoizedSelector<State, Feature[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.features
);

export const selectFeatures = (
  parentFeature: string
): MemoizedSelector<State, Feature[], DefaultProjectorFn<Feature[]>> =>
  createSelector(selectAllFeatures, (features: Feature[]): Feature[] => {
    const subFeatures: Feature[] = [];

    if (features?.length === 0) {
      return null;
    }

    for (const feature of features) {
      if (feature.id.startsWith(`${environment.features.pattern}${parentFeature}`)) {
        subFeatures.push(feature);
      }
    }

    return subFeatures;
  });

export const selectIsAccountFeatureEnabled = (
  featureId: string
): MemoizedSelector<State, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectAllFeatures, (features: Feature[]): boolean => {
    const accountFeature = features?.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${featureId}`
    );

    return accountFeature?.enabled;
  });

export const selectAccountsList: MemoizedSelector<
  State,
  AccountListResponse,
  (s1: sharedReducer.State, s2: Feature[]) => AccountListResponse
> = createSelector(selectSharedState, selectFeatures(`accounts`), (state: sharedReducer.State, features: Feature[]) => {
  const accounts: AccountListResponse = {
    platform: [],
    blockchain: [],
    wallet: [],
    service: [],
  };

  accounts.platform = state.accounts?.platform.filter((account: Account) => {
    const accountFeature = features?.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${account.key}`
    );

    return accountFeature?.enabled;
  });
  accounts.blockchain = state.accounts?.blockchain.filter((account: Account) => {
    const accountFeature = features?.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${account.key}`
    );

    return accountFeature?.enabled;
  });
  accounts.wallet = state.accounts?.wallet.filter((account: Account) => {
    const accountFeature = features?.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${account.key}`
    );

    return accountFeature?.enabled;
  });
  accounts.service = state.accounts?.service.filter((account: Account) => {
    const accountFeature = features?.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${account.key}`
    );

    return accountFeature?.enabled;
  });

  return accounts;
});

export const selectAccounts: MemoizedSelector<
  State,
  Map<string, Account>,
  (s1: AccountListResponse) => Map<string, Account>
> = createSelector(selectAccountsList, (accountsList: AccountListResponse) => {
  const accountsMap: Map<string, Account> = new Map<string, Account>([]);
  if (accountsList) {
    const platforms: Account[] = accountsList?.platform ? [...accountsList.platform] : [];
    const blockchains: Account[] = accountsList?.blockchain ? [...accountsList.blockchain] : [];
    const wallets: Account[] = accountsList?.wallet ? [...accountsList.wallet] : [];
    const services: Account[] = accountsList?.service ? [...accountsList.service] : [];

    const accounts: Account[] = [...platforms, ...blockchains, ...wallets, ...services];

    accounts.forEach((account: Account) => accountsMap.set(account.key, account));
  }

  return accountsMap;
});

export const selectAllAccounts: MemoizedSelector<State, Map<string, Account>> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => {
    const accountsMap: Map<string, Account> = new Map<string, Account>([]);
    if (state.accounts) {
      const platforms: Account[] = state.accounts?.platform ? [...state.accounts.platform] : [];
      const blockchains: Account[] = state.accounts?.blockchain ? [...state.accounts.blockchain] : [];
      const wallets: Account[] = state.accounts?.wallet ? [...state.accounts.wallet] : [];
      const services: Account[] = state.accounts?.service ? [...state.accounts.service] : [];

      const accounts: Account[] = [...platforms, ...blockchains, ...wallets, ...services];

      accounts.forEach((account: Account) => accountsMap.set(account.key, account));
    }

    return accountsMap;
  }
);

export const selectPlatforms: MemoizedSelector<State, Account[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.accounts?.platform
);

export const selectWallets: MemoizedSelector<State, Account[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.accounts?.wallet
);

export const selectServices: MemoizedSelector<State, Account[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.accounts?.service
);

export const selectBlockchains: MemoizedSelector<State, Account[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.accounts?.blockchain
);

export const selectCoins: MemoizedSelector<State, Map<string, string>> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.coins
);

export const selectConfiguration: MemoizedSelector<State, Configuration> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.configuration
);

export const selectIsConfigurationLoaded: MemoizedSelector<State, boolean> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.isConfigurationLoaded
);

export const selectAnalysisCount: MemoizedSelector<State, number> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.intercom.analysisCount
);

export const selectUserPreferences: MemoizedSelector<State, UserPreferences> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.userPreferences
);

export const selectStableCoins: MemoizedSelector<State, string[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.stablecoins
);

export const selectFiats: MemoizedSelector<State, string[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.fiats
);

export const selectIsEmailTransferRequested: MemoizedSelector<State, boolean> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => (state.emailTransferRequest ? state.emailTransferRequest.active : false)
);

export const selectEmailTransferRequest: MemoizedSelector<State, TransferRequest> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.emailTransferRequest
);

export const selectHasEmailTransferError: MemoizedSelector<State, boolean> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.hasEmailTransferError
);

export const selectLanguage: MemoizedSelector<State, string> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.userPreferences.language
);

export const selectUserLocation: MemoizedSelector<State, GeolocationDetails> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.userLocation
);

export const selectFrenchPostCodes: MemoizedSelector<State, Map<string, string>> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.frenchPostCodes
);

export const selectBelgianFiscalProfiles: MemoizedSelector<State, Map<string, string>> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.belgianFiscalProfiles
);

export const selectSpanishAutonomousCommunities: MemoizedSelector<State, Map<string, string>> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.spanishAutonomousCommunities
);

export const selectUserBalance: MemoizedSelector<State, Balance> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.userBalance
);

export const selectOnboardingError: MemoizedSelector<State, CustomError> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.onboarding.onboardingError
);

export const selectIsOnboardingCompleted: MemoizedSelector<State, boolean> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.onboarding.completed
);

export const selectIsPaymentDialogOpened: MemoizedSelector<State, boolean> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.isPaymentDialogOpened
);

export const selectPartner: MemoizedSelector<State, string> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.partner
);

export const selectCurrentOpenedForm: MemoizedSelector<State, string> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.currentOpenedForm
);

export const selectIsTrackingLoaded: MemoizedSelector<State, boolean> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.isTrackingLoaded
);

export const selectUserScamList: MemoizedSelector<State, Scam[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.userScamList
);

export const selectUserReportedTokenList: MemoizedSelector<State, ReportedToken[]> = createSelector(
  selectSharedState,
  (state: sharedReducer.State) => state.userReportedTokenList
);
