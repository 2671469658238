import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as assessmentReducer from '../reducers/assessment.reducer';
import { TaxableCession } from '../../models/taxable-cession.model';
import { Assessment, AssessmentStatus } from '../../models/assessment.model';

export const ASSESSMENT_FEATURE_NAME = `assessment`;

export interface State {
  assessment: assessmentReducer.State;
}

export const selectAssessmentState: MemoizedSelector<State, assessmentReducer.State> =
  createFeatureSelector<assessmentReducer.State>(ASSESSMENT_FEATURE_NAME);

export const selectTaxableCessions: MemoizedSelector<State, TaxableCession[]> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.taxableCessions
);

export const selectAssessment: MemoizedSelector<State, Assessment> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.assessment
);

export const selectAssessmentsByYear: MemoizedSelector<State, Map<string, Assessment>> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.assessmentsByYear
);

export const selectCurrentFiscalYear: MemoizedSelector<State, number> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.currentFiscalYear
);

export const selectAssessmentStatus: MemoizedSelector<State, AssessmentStatus> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.assessmentStatus
);

export const selectAssessmentETA: MemoizedSelector<State, number> = createSelector(
  selectAssessmentState,
  (state: assessmentReducer.State) => state.assessmentETA
);
