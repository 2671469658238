import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { TwoFACheck, UserTwoFAConfiguration } from '../../authentication/models/two-fa.model';
import {
  load2FaConfigAction,
  loadAccessTokenAction,
  loadAuthUserAction,
  loadIsAuthenticatedAction,
  loadUserAction,
} from '../../authentication/store/actions/authentication.action';
import * as fromAuthentication from '../../authentication/store/selectors/authentication.selector';
import * as fromShared from '../../shared/store/selectors/shared.selector';
import { Assessment } from '../../taxation/models/assessment.model';
import { User } from '../../taxation/models/user.model';
import { loadAssessmentAction } from '../../taxation/store/actions/assessment.action';
import * as fromAssessment from '../../taxation/store/selectors/assessment.selector';
import { UserPreferences } from '../models/user-preferences.model';
import {
  goToAction,
  loadAccountsAction,
  loadCoinsAction,
  loadConfigurationAction,
  loadFiatsAction,
  loadStablecoinsAction,
  loadUserPreferencesAction,
  loadUserScamListAction,
  pingUserAction,
} from '../store/actions/shared.action';

export const configurationGuard: CanActivateFn = () => {
  const authenticationStore$ = inject(Store<fromAuthentication.State>);
  const assessmentStore$ = inject(Store<fromAssessment.State>);
  const sharedStore$ = inject(Store<fromShared.State>);

  authenticationStore$.dispatch(loadIsAuthenticatedAction());
  authenticationStore$.dispatch(loadAccessTokenAction());
  authenticationStore$.dispatch(loadAuthUserAction());

  return combineLatest([
    authenticationStore$.select(fromAuthentication.selectIsAuthenticated),
    assessmentStore$.select(fromAssessment.selectAssessment),
    sharedStore$.select(fromShared.selectIsConfigurationLoaded),
    authenticationStore$.select(fromAuthentication.selectUserTwoFA),
    authenticationStore$.select(fromAuthentication.selectIs2FaCodeValidated),
    authenticationStore$.select(fromAuthentication.selectUser),
    sharedStore$.select(fromShared.selectUserPreferences),
    sharedStore$.select(fromShared.selectIsOnboardingCompleted),
  ]).pipe(
    tap(
      ([
        isAuthenticated,
        assessment,
        isConfigurationLoaded,
        userTwoFA,
        is2FaCodeValidated,
        user,
        userPreferences,
        isOnboardingCompleted,
      ]: any) => {
        if (isAuthenticated && !userTwoFA.config) {
          authenticationStore$.dispatch(load2FaConfigAction());
        }

        if (isAuthenticated && userTwoFA.config && !user) {
          authenticationStore$.dispatch(loadUserAction());
        }

        if (isAuthenticated && userTwoFA.config && user && !isConfigurationLoaded) {
          sharedStore$.dispatch(pingUserAction({}));
          assessmentStore$.dispatch(loadAssessmentAction());
          sharedStore$.dispatch(loadAccountsAction());
          sharedStore$.dispatch(loadCoinsAction());
          sharedStore$.dispatch(loadUserScamListAction());
          sharedStore$.dispatch(loadStablecoinsAction());
          sharedStore$.dispatch(loadFiatsAction());
          sharedStore$.dispatch(loadConfigurationAction());
        }

        if (isAuthenticated && userTwoFA.config && user && isConfigurationLoaded && !userPreferences) {
          sharedStore$.dispatch(loadUserPreferencesAction());
        }
      }
    ),
    filter(
      ([
        isAuthenticated,
        assessment,
        isConfigurationLoaded,
        userTwoFA,
        is2FaCodeValidated,
        user,
        userPreferences,
        isOnboardingCompleted,
      ]: [boolean, Assessment, boolean, TwoFACheck, boolean, User, UserPreferences, boolean]) =>
        isAuthenticated &&
        user !== null &&
        isConfigurationLoaded &&
        userPreferences !== null &&
        userTwoFA.config !== null
    ),
    map(
      ([
        isAuthenticated,
        assessment,
        isConfigurationLoaded,
        userTwoFA,
        is2FaCodeValidated,
        user,
        userPreferences,
        isOnboardingCompleted,
      ]: [boolean, Assessment, boolean, TwoFACheck, boolean, User, UserPreferences, boolean]) => {
        const userTwoFaConfig: UserTwoFAConfiguration = userTwoFA.config;

        if (userTwoFaConfig.enabled && !is2FaCodeValidated) {
          sharedStore$.dispatch(goToAction({ url: `2fa` }));
          return false;
        } else if (user?.needToSignTCs && !isOnboardingCompleted) {
          sharedStore$.dispatch(goToAction({ url: `onboarding` }));
          return false;
        } else {
          return true;
        }
      }
    )
  );
};
