import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  url: `https://tax.waltio.com`,
  apiUrl: `https://data.waltio.com/live`,
  stripe: `pk_live_fXaIfiJtFZoLLcyQhW2dRDBl`,
  couchDbUrl: `couchdb/assessment-prod`,
  gtmId: `GTM-5J4MMHJ`,
  auth0: {
    callback: `https://tax.waltio.com/callback`,
    clientID: `eXaIoqNRXwxNE6Td4OT3rh1XFp0RzsJ8`,
    domain: `auth.waltio.com`,
  },
  intercom: {
    secret: `RYggsG21Lm43TU0KCBCbN3RaiC4wQHgQLvBgHd-C`,
  },

  features: {
    pattern: `tax:prod:`,
  },
};
