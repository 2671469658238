/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { CustomError } from '../../../shared/models/error.model';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { AccountTag } from '../../../shared/models/tag.model';
import { AccountService } from '../../../shared/services/account.service';
import { ToastService } from '../../../shared/services/toast.service';
import { goToAction, pushTagAction } from '../../../shared/store/actions/shared.action';
import { selectCurrentRoute, selectQueryParam } from '../../../shared/store/selectors/router.selector';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { Account, AccountListResponse } from '../../models/account.model';
import { RedirectionUrl } from '../../models/api.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { APIDetails, Upload } from '../../models/upload.model';
import { UserAccount } from '../../models/user-account.model';
import { APIService } from '../../services/api.service';
import { FileService } from '../../services/file.service';
import { NameResolverService } from '../../services/name-resolver.service';
import {
  createApiAction,
  createOAuthAPIAction,
  createSelectedCandidatesApisAction,
  deleteAccountAction,
  downloadFileAction,
  getOAuthSyncUrlAction,
  getUserAccountAction,
  loadAvailableCandidatesAction,
  loadTimezonesAction,
  loadTransactionsCountAction,
  loadUserAPIsAction,
  loadUserAccountsAction,
  removeUserAccountAction,
  renameAccountAction,
  resolveNameAction,
  setApiConnectedAction,
  setApiErrorMessageAction,
  setAvailableCandidatesAction,
  setResolvedNameAction,
  setTimezonesAction,
  setTransactionsCountAction,
  setUserAPIsAction,
  setUserAccountsAction,
  syncAPIAction,
  updateApiAction,
  updateUserAccountAction,
} from '../actions/account.action';
import { computeAssessmentStatusAction } from '../actions/assessment.action';
import { loadPlansByFiscalYearsAction } from '../actions/payment.action';
import * as fromAccount from '../selectors/account.selector';
import { Candidate } from '../../models/candidate.model';

@Injectable()
export class AccountEffects {
  createApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createApiAction>>(createApiAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccountsList))),
      delay(2000),
      switchMap(([action, accountsList]: [ReturnType<typeof createApiAction>, AccountListResponse]) =>
        this.apiService.createAPI(action.account.key, action.api, action.alias, action.subAccount?.key).pipe(
          switchMap((upload: Upload) => {
            const message = ``
              .concat(this.translateService.instant(`TOAST.ACCOUNT`))
              .concat(` "${action.subAccount?.name || action.account?.name}" `)
              .concat(this.translateService.instant(`TOAST.ADDED`));

            const tag: AccountTag = {
              event: `import_account`,
              account: action.subAccount?.key || action.account.key,
              import_type: `API`,
            };

            this.toastService.success(message);

            const actions: any[] = [pushTagAction({ tag })];
            actions.push(setApiConnectedAction());

            const checkAvailableChains = [...accountsList.blockchain, ...accountsList.wallet]
              .map((a: Account) => a.key)
              .includes(action.subAccount?.key || action.account.key);

            if (checkAvailableChains) {
              actions.push(
                loadAvailableCandidatesAction({
                  apiId: upload.id,
                  address: action.api.key,
                  aggregator: action.subAccount ? action.account.key : ``,
                })
              );
            }

            return actions;
          }),
          catchError((error: CustomError) => {
            let errorMessage = error?.message;
            if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
              errorMessage = this.translateService.instant(error.errorCode);
            }

            const tag: AccountTag = {
              event: `account_import_failure`,
              account: action.subAccount?.key || action.account.key,
              import_type: `API`,
              error_message: errorMessage,
            };

            return [pushTagAction({ tag }), setApiErrorMessageAction({ apiErrorMessage: errorMessage })];
          })
        )
      )
    )
  );

  updateApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateApiAction>>(updateApiAction),
      switchMap((action: ReturnType<typeof updateApiAction>) =>
        this.apiService.updateAPI(action.apiId, action.api).pipe(
          switchMap(() => {
            return [setApiConnectedAction(), loadUserAccountsAction()];
          }),
          catchError((error: CustomError) => {
            let errorMessage = error?.message;
            if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
              errorMessage = this.translateService.instant(error.errorCode);
            }

            return [setApiErrorMessageAction({ apiErrorMessage: errorMessage })];
          })
        )
      )
    )
  );

  loadAvailableCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailableCandidatesAction>>(loadAvailableCandidatesAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
      switchMap(([action, accounts]: [ReturnType<typeof loadAvailableCandidatesAction>, Map<string, Account>]) =>
        this.apiService.getAvailableCandidates(action.apiId, action.address, action.aggregator).pipe(
          map((availableCandidates: Candidate[]) => {
            return setAvailableCandidatesAction({ availableCandidates });
          }),
          catchError((error: CustomError) => EMPTY)
        )
      )
    )
  );

  createSelectedCandidatesApis$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof createSelectedCandidatesApisAction>>(createSelectedCandidatesApisAction),
        withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
        switchMap(
          ([action, accounts]: [ReturnType<typeof createSelectedCandidatesApisAction>, Map<string, Account>]) => {
            let apis$: Observable<Upload>[] = [];

            apis$ = action.selectedCandidates.map((candidate: Candidate) => {
              const apiDetails: APIDetails = {
                key: candidate.address,
              };
              return this.apiService.createAPI(candidate.platform, apiDetails, action.alias);
            });

            return combineLatest(apis$).pipe(
              mergeMap((apis: Upload[]) => {
                const timeOut = 2000;
                this.toastService.dismiss();

                apis.forEach((api: Upload, index: number) => {
                  setTimeout(() => {
                    const message = ``
                      .concat(this.translateService.instant(`TOAST.ACCOUNT`))
                      .concat(` "${accounts.get(api.platform)?.name}" `)
                      .concat(this.translateService.instant(`TOAST.ADDED`));

                    this.toastService.success(message);
                  }, index * (timeOut + 500));
                });

                return EMPTY;
              }),
              catchError((error: CustomError) => {
                const errorMessage: string =
                  this.translateService.instant(error.errorCode) === error.errorCode
                    ? error.message
                    : this.translateService.instant(error.errorCode);

                this.toastService.dismiss();

                this.toastService.error(errorMessage);

                return EMPTY;
              })
            );
          }
        )
      ),
    { dispatch: false }
  );

  resolveName$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof resolveNameAction>>(resolveNameAction),
      switchMap((action: ReturnType<typeof resolveNameAction>) =>
        this.nameResolverService.resolveName(action.account.key, action.name).pipe(
          map((resolvedName: NameResolverResponse) => setResolvedNameAction({ resolvedName })),
          catchError((error: CustomError) => {
            let apiErrorMessage = error?.message;
            if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
              apiErrorMessage = this.translateService.instant(error.errorCode);
            }

            return [
              setResolvedNameAction({ resolvedName: null }),
              setApiErrorMessageAction({
                apiErrorMessage,
              }),
            ];
          })
        )
      )
    )
  );

  loadUserAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserAccountsAction>>(loadUserAccountsAction),
      switchMap(() => {
        return this.accountService.getUserAccounts().pipe(
          map((userAccounts: UserAccount[]) => {
            return setUserAccountsAction({ userAccounts });
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        );
      })
    )
  );

  setUserAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setUserAccountsAction>>(setUserAccountsAction),
      map(() => computeAssessmentStatusAction())
    )
  );

  loadUserAPIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserAPIsAction>>(loadUserAPIsAction),
      switchMap(() =>
        this.apiService.getApis().pipe(
          map((userAPIs: Upload[]) => setUserAPIsAction({ userAPIs })),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadTransactionsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTransactionsCountAction>>(loadTransactionsCountAction),
      switchMap(() =>
        this.fileService.getAccountsTransactionsCount().pipe(
          map((transactionsCount: Map<string, number>) =>
            setTransactionsCountAction({
              transactionsCount,
            })
          )
        )
      )
    )
  );

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadFileAction>>(downloadFileAction),
        switchMap((action: ReturnType<typeof downloadFileAction>) =>
          this.fileService.downloadFile(action.account.accountId).pipe(
            tap((data: any) => {
              // Doing it this way allows you to name the file
              const link = document.createElement(`a`);

              link.href = window.URL.createObjectURL(new Blob([data], { type: `application/zip` }));

              link.download = action.account.name + `.zip`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          )
        )
      ),
    { dispatch: false }
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteAccountAction>>(deleteAccountAction),
      withLatestFrom(
        this.sharedStore$.pipe(select(fromShared.selectAccounts)),
        this.accountStore$.pipe(select(fromAccount.selectUserAccounts))
      ),
      switchMap(
        ([action, accounts, userAccounts]: [
          ReturnType<typeof deleteAccountAction>,
          Map<string, Account>,
          UserAccount[]
        ]) =>
          this.fileService.deleteFile(action.account.accountId).pipe(
            switchMap(() => {
              const tag: AccountTag = {
                event: `delete_account`,
                account: action.account.platform,
                added_date: action.account.uploaded,
                transactions_count: action.account.nbOfTransactions,
                import_type: action.account.type,
                last_sync_date: action.account.lastUpdated || action.account.uploaded,
              };

              const msg = this.translateService
                .instant(`TOAST.ACCOUNT`)
                .concat(` "${action.account.alias || accounts.get(action.account.platform)?.name}" `)
                .concat(this.translateService.instant(`TOAST.REMOVED`));

              this.toastService.success(msg);

              return [
                pushTagAction({ tag }),
                removeUserAccountAction({ userAccount: action.account }),
                loadPlansByFiscalYearsAction(),
              ];
            }),
            catchError((error: CustomError) => {
              this.toastService.error(error.message);

              return [setUserAccountsAction({ userAccounts }), loadPlansByFiscalYearsAction()];
            })
          )
      )
    )
  );

  renameAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof renameAccountAction>>(renameAccountAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
      switchMap(([action, accounts]: [ReturnType<typeof renameAccountAction>, Map<string, Account>]) => {
        const updateAlias$: Observable<Upload> =
          action.account.type === `API`
            ? this.apiService.updateApiAlias(action.account.accountId, action.alias)
            : this.fileService.updateFileAlias(action.account.accountId, action.alias);

        return updateAlias$.pipe(
          switchMap(() => {
            const tag: AccountTag = {
              event: `rename_account`,
              account: action.account.platform,
              added_date: action.account.uploaded,
              transactions_count: action.account.nbOfTransactions,
              import_type: action.account.type,
              last_sync_date: action.account.lastUpdated || action.account.uploaded,
            };

            // Le compte <X> a été renommé.
            const message = this.translateService
              .instant(`ACCOUNT_ALIAS_UPDATED`)
              .replace(`{account}`, action.account.alias || accounts.get(action.account.platform).name);

            this.toastService.success(message);

            return [pushTagAction({ tag }), getUserAccountAction({ accountId: action.account.accountId })];
          })
        );
      })
    )
  );

  getOAuthSyncUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof getOAuthSyncUrlAction>>(getOAuthSyncUrlAction),
        switchMap((action: ReturnType<typeof getOAuthSyncUrlAction>) =>
          this.apiService.getOAuthSyncUrl(action.platform).pipe(
            map((redirectionUrl: RedirectionUrl) => {
              if (action.platform === `BITSTAMP`) {
                localStorage.setItem(`codeVerifier`, redirectionUrl.codeVerifier);
              }

              window.location.href = redirectionUrl.url;
            }),
            catchError((error: CustomError) => {
              this.toastService.error(error.message);

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  createOAuthAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createOAuthAPIAction>>(createOAuthAPIAction),
      withLatestFrom(
        this.store$.select(selectCurrentRoute),
        this.store$.pipe(select(selectQueryParam(`code`))),
        this.sharedStore$.pipe(select(fromShared.selectAccounts))
      ),
      switchMap(
        ([action, route, code, accounts]: [
          ReturnType<typeof createOAuthAPIAction>,
          any,
          string,
          Map<string, Account>
        ]) => {
          const account: string = route.routeConfig.path.replace(`-sync/callback`, ``).toUpperCase();

          return this.apiService.createOAuthAPI(account, code).pipe(
            switchMap((upload: Upload) => {
              const accountName = accounts.get(upload.platform)?.name || upload.platform;
              const message = ``
                .concat(this.translateService.instant(`TOAST.ACCOUNT`))
                .concat(` "${accountName}" `)
                .concat(this.translateService.instant(`TOAST.ADDED`));

              const tag: AccountTag = {
                event: `import_account`,
                account: account,
                import_type: `OAUTH`,
              };

              this.toastService.success(message);

              return [pushTagAction({ tag }), goToAction({ url: `/accounts` })];
            }),
            catchError((error: CustomError) => {
              const tag: AccountTag = {
                event: `account_import_failure`,
                import_type: `OAUTH`,
                account: account,
                error_message: error.message,
              };

              return [pushTagAction({ tag })];
            })
          );
        }
      )
    )
  );

  loadTimezones$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTimezonesAction>>(loadTimezonesAction),
      switchMap((action: ReturnType<typeof loadTimezonesAction>) =>
        this.fileService.getTimezones().pipe(
          map((timezones: TimezoneDetails[]) => setTimezonesAction({ timezones })),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  getUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getUserAccountAction>>(getUserAccountAction),
      withLatestFrom(this.accountStore$.pipe(select(fromAccount.selectUserAccounts))),
      switchMap(([action, userAccounts]: [ReturnType<typeof getUserAccountAction>, UserAccount[]]) =>
        this.accountService.getUserAccount(action.accountId).pipe(
          map((account: UserAccount) => {
            const index = userAccounts.findIndex((a: UserAccount) => a.accountId === account.accountId);
            userAccounts[index].status = account.status;
            userAccounts[index].lastUpdated = account.lastUpdated;
            userAccounts[index].nbOfTransactions = account.nbOfTransactions;
            userAccounts[index].manualSyncCredit = account.manualSyncCredit;

            return updateUserAccountAction({ userAccount: userAccounts[index] });
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  syncAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof syncAPIAction>>(syncAPIAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
      switchMap(([action, userAccounts]: [ReturnType<typeof syncAPIAction>, Map<string, Account>]) =>
        this.apiService.syncAPI(action.userAccount.accountId).pipe(
          map(() => {
            const message = this.translateService
              .instant(`ACCOUNT_SYNCHRONIZING`)
              .replace(`{account}`, action.userAccount.alias || userAccounts.get(action.userAccount.platform).name);

            this.toastService.success(message);

            return getUserAccountAction({ accountId: action.userAccount.accountId });
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly accountStore$: Store<fromAccount.State>,
    private readonly apiService: APIService,
    private readonly nameResolverService: NameResolverService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly fileService: FileService,
    private readonly toastService: ToastService,
    private readonly accountService: AccountService
  ) {}
}
