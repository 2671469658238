import { UserAddon, UserPlan } from '../models/user.model';

export const PLANS: Map<UserPlan, any> = new Map<UserPlan, any>([
  [`PLN000`, {
    maximumNbOfTransactions: 50,
    price: 0
  }],
  [`PLN300`, {
    maximumNbOfTransactions: 1000,
    price: 99.00,
    details: [
      `PLN300.TRANSACTIONS`,
      `DeFi, staking, farming, NFTs...`,
      `PLN300.REVENUE`,
      `PLN300.ITEM`,
      `PLN300.CERTIFICATE`
    ]
  }],
  [`PLN500`, {
    maximumNbOfTransactions: 10000,
    price: 199.00,
    details: [
      `PLN500.PLAN`,
      `PLN500.TRANSACTIONS`,
      `PLN500.SYNC`,
      `PLN500.ITEM`,
      `PLN500.TAX`
    ]
  }],
  [`PLN800`, {
    maximumNbOfTransactions: -1,
    price: 999.00,
    details: [
      `PLN800.PLAN`,
      `PLN800.TRANSACTIONS`,
      `PLN800.FISCAL_CONTROL`,
      `PLN800.SYNC`,
      `PLN800.ITEM`
    ]
  }]
]);

export const ADDONS: Map<UserAddon, number> = new Map<UserAddon, number>([
  [`AOCARE001`, 799.00],
  [`AOCARE002`, 2999.00],
]);