import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User } from '../../taxation/models/user.model';
import { environment } from '../../../environments/environment';
import { UserPreferences } from '../models/user-preferences.model';

@Injectable({
  providedIn: `root`,
})
export class OnboardingService {
  constructor(private readonly http: HttpClient) {}

  completeOnboarding(affiliateId: string, user: User, preferences: UserPreferences): Observable<User> {
    let url = `${environment.apiUrl}/v1/tax/onboarding/full`;

    const userAndPreferences: any = {
      user,
      preferences,
    };

    if (affiliateId) {
      url = url.concat(`?affiliateId=${affiliateId}`);
    }

    return this.http.post<User>(url, userAndPreferences);
  }

  getBelgianFiscalProfiles(): Observable<Map<string, string>> {
    return this.http
      .get<Map<string, string>>(`${environment.apiUrl}/v1/tax/onboarding/belgian-fiscal-profile/list`)
      .pipe(
        map((belgianFiscalProfiles) => {
          return new Map<string, string>(Object.entries(belgianFiscalProfiles));
        })
      );
  }
}
