<div class="checkout">
  <section>
    <div
      class="checkout__title"
      [ngClass]="{
        'checkout__title--charge': checkoutType === 'CHARGE'
      }"
      >
      @if (checkoutType === 'CHARGE') {
        <h1>{{ 'PERSONALIZED_SERVICE' | translate }}</h1>
      }

      <img class="pointer" src="/assets/img/icons/close.svg" alt="" (click)="dialogRef.close()" />
    </div>
    <div class="checkout__item">
      @if (checkoutType !== 'CHARGE') {
        <p class="body-14-med">
          {{ selectedPlanLabel }}
        </p>
      }

      <!-- Offer -->
      @if (checkoutType !== 'CHARGE') {
        <div class="selected-offer">
          <div class="selected-offer__label">
            <img src="/assets/img/icons/check-purple.svg" alt="" />
            @if (checkoutType === 'PLAN') {
              <p class="body-14-bold">
                {{ planLabel }}
              </p>
            }
            @if (checkoutType === 'ADDON' && paymentEstimate) {
              <p class="body-14-bold">
                {{ 'ADDONS.' + paymentEstimate.addon | translate }}
              </p>
            }
          </div>
          @if (checkoutType === 'PLAN' || checkoutType === 'ADDON') {
            <p class="body-14-bold">
              {{ paymentEstimate?.subtotal | currencyDecimal : userPreferences : stablecoins : fiats }}
            </p>
          }
        </div>
      }

      <!-- Charges -->
      @if (checkoutType === 'CHARGE') {
        <div class="selected-offer">
          <div class="selected-offer__label">
            <img src="/assets/img/icons/check-purple.svg" alt="" />
            <p class="body-14-bold">{{ pendingCharge.charge.description }}</p>
          </div>
          <p class="body-14-bold">
            {{ pendingCharge.charge.price | currencyDecimal : userPreferences : stablecoins : fiats }}
          </p>
        </div>
      }
    </div>

    <div class="checkout__item">
      <p class="body-14-med">{{ 'PAYMENT_METHOD' | translate }}</p>

      @if (!showOtherPaymentMethods) {
        <div class="default-payment-method">
          <img src="/assets/img/icons/card.svg" alt="" />
          <p class="body-14-bold">{{ 'STRIPE' | translate }}</p>
        </div>
        <div class="f-row pointer" (click)="showOtherPaymentMethods = true">
          <p class="body-13-med">{{ 'OTHER_PAYMENT_METHOD' | translate }}</p>
          <img src="/assets/img/icons/next.svg" alt="" />
        </div>
      }

      @if (showOtherPaymentMethods) {
        <div class="payment-methods">
          <app-payment-method
            [paymentMethod]="'STRIPE'"
            [isSelected]="selectedPaymentMethod === 'STRIPE'"
            (click)="selectPaymentMethod('STRIPE')"
          ></app-payment-method>
          <app-payment-method
            [paymentMethod]="'COINBASE'"
            [isSelected]="selectedPaymentMethod === 'COINBASE'"
            (click)="selectPaymentMethod('COINBASE')"
          ></app-payment-method>
        </div>
      }
    </div>
  </section>

  <mat-divider></mat-divider>

  <section>
    <div class="checkout__item">
      <p class="body-14-med">{{ 'PAYMENT_SUMMARY' | translate }}</p>
      @if (checkoutType !== 'CHARGE') {
        <app-vault
          [vaultBalance]="vaultBalance - vaultBalanceUsed"
          [userPreferences]="userPreferences"
          [fiats]="fiats"
          [stablecoins]="stablecoins"
          [useVault]="useVault"
          [useVoucher]="useVoucher"
          (useVaultChange)="useVaultChange($event)"
        ></app-vault>
      }
    </div>

    @if (checkoutType !== 'CHARGE') {
      <app-voucher
        [isEnabled]="!useVault"
        [appliedVouchers]="paymentEstimate?.appliedVouchers"
        [voucherResponse]="voucherResponse"
        (addVoucher)="checkVoucherCode($event)"
        (removeVoucher)="removeVoucher()"
      ></app-voucher>
    }

    <div class="checkout__amounts">
      @if (checkoutType !== 'CHARGE') {
        <div class="amount">
          <p class="body-14">{{ 'SUBTOTAL' | translate }}</p>
          @if (checkoutType === 'PLAN') {
            <p class="body-14">
              {{ paymentEstimate?.totalPlan | currencyDecimal : userPreferences : stablecoins : fiats }}
            </p>
          }
          @if (checkoutType === 'ADDON') {
            <p class="body-14">
              {{ paymentEstimate?.totalAddon | currencyDecimal : userPreferences : stablecoins : fiats }}
            </p>
          }
        </div>
        @for (voucher of paymentEstimate?.appliedVouchers; track voucher) {
          <div class="amount">
            <p class="body-14">{{ voucher.code | uppercase | translate }}</p>
            <p class="body-14">-{{ voucher.amount | currencyDecimal : userPreferences : stablecoins : fiats }}</p>
          </div>
        }
        <mat-divider></mat-divider>
      }

      <div class="amount">
        <p class="body-14-med">{{ 'TOTAL_TO_PAY' | translate }}</p>

        @if (checkoutType !== 'CHARGE') {
          <p class="body-14-med">
            {{ paymentEstimate?.due | currencyDecimal : userPreferences : stablecoins : fiats }}
          </p>
        }
        @if (checkoutType === 'CHARGE') {
          <p class="body-14-med">
            {{ pendingCharge.charge.price | currencyDecimal : userPreferences : stablecoins : fiats }}
          </p>
        }
      </div>
    </div>

    @if (checkoutType === 'PLAN') {
      <button type="button" mat-flat-button color="primary" (click)="upgradePlan()">
        {{ subscribeCTA }}
      </button>
    }

    @if (checkoutType === 'ADDON') {
      <button type="button" mat-flat-button color="primary" (click)="enableAddon()">
        {{ 'PAY' | translate }}
      </button>
    }

    @if (checkoutType === 'CHARGE') {
      <button type="button" mat-flat-button color="primary" (click)="payCharge()">
        {{ 'PAY' | translate }}
      </button>
    }
  </section>
</div>
