import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { Transaction, TransactionFilters, TransactionsTab, TransactionWarning } from '../../models/transaction.model';
import * as transactionReducer from '../reducers/transaction.reducer';
import { TransactionStatsAggregation } from '../../models/transaction-stats-aggregation.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { WarningOccurences } from '../../models/warning.model';

export const TRANSACTIONS_FEATURE_NAME = `transactions`;

export interface State {
  transactions: transactionReducer.State;
}

export const selectTransactionState: MemoizedSelector<State, transactionReducer.State> =
  createFeatureSelector<transactionReducer.State>(TRANSACTIONS_FEATURE_NAME);

export const selectTransactionsPage: MemoizedSelector<State, Page<Transaction>> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.transactionsPage
);

export const selectFilters: MemoizedSelector<State, TransactionFilters> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.filters
);

export const selectAppliedFilters: MemoizedSelector<State, any> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => {
    switch (state.selectedTransactionsTab) {
      case `transactions`:
        return state.transactionsTabFilters;
      case `warnings`:
        return state.warningsTabFilters;
      case `taxable`:
        return state.taxableTabFilters;
      default:
        return state.transactionsTabFilters;
    }
  }
);

export const selectSelectedTransaction: MemoizedSelector<State, Transaction> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.selectedTransaction
);

export const selectCheckedTransactions: MemoizedSelector<State, Transaction[]> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.checkedTransactions
);

export const selectTransactionsStats: MemoizedSelector<State, TransactionStatsAggregation> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.transactionsStats
);

export const selectNegativeBalances: MemoizedSelector<State, TokenAndPlatformBalanceDetail[]> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.negativeBalances
);
export const selectTransactionsTabCount: MemoizedSelector<State, number> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.transactionsTabCount
);

export const selectWarningsTabCount: MemoizedSelector<State, number> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.warningsTabCount
);

export const selectTaxableTabCount: MemoizedSelector<State, number> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.taxableTabCount
);

export const selectSelectedTransactionsTab: MemoizedSelector<State, TransactionsTab> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.selectedTransactionsTab
);

export const selectSelectedNegativeBalanceWarning: MemoizedSelector<State, TransactionWarning> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.selectedNegativeBalanceWarning
);

export const selectTransactionsWarnings: MemoizedSelector<State, WarningOccurences[]> = createSelector(
  selectTransactionState,
  (state: transactionReducer.State) => state.transactionsWarnings
);
