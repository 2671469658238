import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MarketHistory } from '../models/market.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: `root`,
})
export class InsightService {
  constructor(private readonly http: HttpClient) {}

  getDashboardHistory(): Observable<MarketHistory> {
    return this.http.get<MarketHistory>(`${environment.apiUrl}/v1/insight/dashboard/history/hour`).pipe(
      map((marketHistory: MarketHistory) => {
        marketHistory.marketHistory = new Map<string, number[]>(Object.entries(marketHistory.marketHistory));

        return marketHistory;
      })
    );
  }
}
