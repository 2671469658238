<div class="container">
  <section
    class="theme"
    [ngClass]="{
    'theme--pln300': offer === 'PLN300',
    'theme--pln500': offer === 'PLN500',
    'theme--pln800': offer === 'PLN800',
    'theme--addon': data.checkoutType === 'ADDON',
  }"
  ></section>
  <section class="content">
    <div class="header">
      @if (data.checkoutType === 'PLAN') {
        <h2>{{ title }}</h2>
      }

      @if (data.checkoutType === 'ADDON') {
        @if (offer === 'AOCARE001' || !offer) {
          <h2>{{ 'UPGRADE_FISHING.AOCARE001' | translate }}</h2>
        }
        @if (offer === 'AOCARE002') {
          <h2>{{ 'UPGRADE_FISHING.AOCARE002' | translate }}</h2>
        }
      }

      <img class="pointer" src="/assets/img/icons/close.svg" alt="close" mat-dialog-close />
    </div>

    @if (!data.offer && data.checkoutType === 'ADDON') {
      <div class="addons">
        <app-offer-toggle
          [offer]="'AOCARE001'"
          [isChecked]="!offer || offer === 'AOCARE001'"
          (click)="offer = 'AOCARE001'; getEstimate()"
        ></app-offer-toggle>
        <app-offer-toggle
          [offer]="'AOCARE002'"
          [isChecked]="offer === 'AOCARE002'"
          (click)="offer = 'AOCARE002'; getEstimate()"
        ></app-offer-toggle>
      </div>
    }

    <div class="features">
      <div class="features__title">
        @if (data.title) {
          <p class="body-14-bold">
            {{ data.title | translate }}
          </p>
        }

        @if (!data.title) {
          @if (offer === 'PLN300' || offer === 'PLN500' || offer === 'PLN800') {
            <p class="body-14-bold">
              {{ subtitle }}
            </p>
          }
          @if (offer === 'AOCARE001' || !offer) {
            <p class="body-14-bold">{{ 'AOCARE001.UF' | translate }}</p>
          }
          @if (offer === 'AOCARE002') {
            <p class="body-14-bold">{{ 'AOCARE002.UF' | translate }}</p>
          }
        }
      </div>
      <div class="features__content">
        @if (data.checkoutType === 'PLAN') {
          <div class="feature">
            @if (offer === 'PLN300') {
              <img src="/assets/img/icons/folder-files-grey.svg" alt="" />
            }
            @if (offer === 'PLN500') {
              <img src="/assets/img/icons/folder-files.svg" alt="" />
            }
            @if (offer === 'PLN800') {
              <img src="/assets/img/icons/folder-files-gold.svg" alt="" />
            }
            <p class="body-14-bold">{{ 'UPGRADE_FISHING.PLATFORMS' | translate }}</p>
          </div>
          <div class="feature">
            @if (offer === 'PLN300') {
              <img src="/assets/img/icons/pie-chart-grey.svg" alt="" />
            }
            @if (offer === 'PLN500') {
              <img src="/assets/img/icons/pie-chart.svg" alt="" />
            }
            @if (offer === 'PLN800') {
              <img src="/assets/img/icons/pie-chart-gold.svg" alt="" />
            }
            <p class="body-14-bold">{{ 'UPGRADE_FISHING.REPORT' | translate }}</p>
          </div>
          <div class="feature">
            @if (offer === 'PLN300') {
              <img src="/assets/img/icons/academic-cap-grey.svg" alt="" />
            }
            @if (offer === 'PLN500') {
              <img src="/assets/img/icons/academic-cap.svg" alt="" />
            }
            @if (offer === 'PLN800') {
              <img src="/assets/img/icons/academic-cap-gold.svg" alt="" />
            }
            @if (offer === 'PLN800') {
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.SUPPORT' | translate }}</p>
            }
            @if (offer !== 'PLN800') {
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.HELP' | translate }}</p>
            }
          </div>
        }
        @if (offer === 'AOCARE001' || !offer) {
          <div class="feature">
            <img src="/assets/img/icons/folder-files-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE001.UF1' | translate }}</p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/pie-chart-black.svg" alt="" />
            <p class="body-14-bold">
              {{ 'AOCARE001.UF2' | translate }}
            </p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/academic-cap-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE001.UF3' | translate }}</p>
          </div>
        }
        @if (offer === 'AOCARE002') {
          <div class="feature">
            <img src="/assets/img/icons/folder-files-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE002.UF1' | translate }}</p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/pie-chart-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE002.UF2' | translate }}</p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/academic-cap-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE002.UF3' | translate }}</p>
          </div>
        }
      </div>
    </div>

    <div class="ctas">
      @if (data.demoCTA) {
        <button
          type="button"
          mat-flat-button
          color="secondary"
          disableRipple
          (click)="downloadDemoFile()"
          >
          {{ data.demoCTA | translate }}
          <img src="/assets/img/icons/download-black.svg" alt="" />
        </button>
      }

      <button
        type="button"
        mat-flat-button
        color="primary"
        disableRipple
        (click)="paymentEstimate ? initCheckoutDialog() : null"
        >
        @if (paymentEstimate) {
          {{ 'SUBSCRIBE_FOR' | translate }}
          {{ paymentEstimate.due | currencyDecimal : userPreferences : stablecoins : fiats }}
        }
        @if (!paymentEstimate) {
          <img width="20px" class="rotating" src="/assets/img/icons/processing.svg" alt="" />
        }
      </button>

      <div class="subscription">
        <p class="body-12">{{ 'FISCAL_YEAR_SUBSCRIPTION' | translate }}</p>
        <div
          class="info-button"
          (click)="openArticle(5830168)"
          [matTooltip]="'SHOW_MORE_INFOS' | translate"
          matTooltipPosition="right"
        ></div>
      </div>
    </div>
  </section>
</div>
