import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  AvailableAddons,
  AvailablePlans,
  FiscalYearPlanEstimate,
  Payment,
  PaymentEstimateV3,
  PendingCharge,
} from '../../models/payment.model';
import * as paymentReducer from '../reducers/payment.reducer';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { SubscriptionDetails, SubscriptionNextPayment } from '../../models/subscription.model';

export const PAYMENTS_FEATURE_NAME = `payments`;

export interface State {
  payments: paymentReducer.State;
}

export const selectPaymentState: MemoizedSelector<State, paymentReducer.State> =
  createFeatureSelector<paymentReducer.State>(PAYMENTS_FEATURE_NAME);

export const selectPayments: MemoizedSelector<State, Payment[]> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.payments
);

export const selectAvailablePlans: MemoizedSelector<State, AvailablePlans> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.availablePlans
);

export const selectAvailableAddons: MemoizedSelector<State, AvailableAddons> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.availableAddons
);

export const selectPendingCharge: MemoizedSelector<State, PendingCharge> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.pendingCharge
);

export const selectValidatedPayment: MemoizedSelector<State, Payment> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.validatedPayment
);

export const selectSubscriptionDetails: MemoizedSelector<State, SubscriptionDetails> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.subscriptionDetails
);

export const selectVoucherResponse: MemoizedSelector<State, GenericResponse> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.voucherResponse
);

export const selectPaymentEstimate: MemoizedSelector<State, PaymentEstimateV3> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.paymentEstimate
);

export const selectUpgradeFishingEstimate: MemoizedSelector<State, PaymentEstimateV3> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.upgradeFishingEstimate
);

export const selectPlansByFiscalYears: MemoizedSelector<State, Map<string, FiscalYearPlanEstimate>> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.plansByFiscalYears
);

export const selectSubscriptionNextPayment: MemoizedSelector<State, SubscriptionNextPayment> = createSelector(
  selectPaymentState,
  (state: paymentReducer.State) => state.subscriptionNextPayment
);
